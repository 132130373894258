import React, { useState, useRef } from "react";
import LoginModal from "../components/LoginModal";
import '../assets/styles/landingpage.css';

const LandingPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const loginButtonRef = useRef(); // Referência para o botão de login

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className="landing-page">
      <header className="header flex justify-between items-center px-8 py-4 bg-white shadow-md">
        <img src="/logo192.png" alt="Hipervias Logo" className="logo h-12" />
        <nav>
          <button 
            onClick={toggleModal} 
            className="btn-login text-blue-600 hover:text-blue-800"
            ref={loginButtonRef} // Conectando a referência ao botão
          >
            Login
          </button>
        </nav>
      </header>

      <section className="hero-section flex flex-col justify-center items-center text-center py-24 bg-gray-100">
        <h1 className="text-6xl font-bold mb-4">Precificação Logística Simplificada</h1>
        <p className="text-xl font-light mb-8 max-w-3xl">
          Com a Hipervias, sua cotação fica pronta em segundos, permitindo que você foque em outras atividades importantes da sua empresa.
        </p>
        <a href="/signup" className="cta-button bg-blue-600 text-white py-3 px-6 rounded-lg text-lg hover:bg-blue-700">Cadastre-se Agora</a>
      </section>

      <section className="about-section text-center py-16 bg-white">
        <h2 className="text-4xl font-semibold mb-6">Por que Hipervias?</h2>
        <p className="text-lg font-light max-w-3xl mx-auto">
          A maior dificuldade das empresas de logística está na criação de tabelas de preço para diferentes destinos, distâncias e veículos. A Hipervias resolve esse problema com um banco de dados abrangente e ferramentas avançadas de gestão.
        </p>
      </section>

      <section className="features-section py-16 bg-gray-100">
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
          <div className="feature-item">
            <h3 className="text-3xl font-semibold mb-4">Painel de Cotações</h3>
            <p className="text-lg font-light">
              Gerencie suas cotações em andamento e transforme-as em instruções de embarque para faturamento.
            </p>
          </div>
          <div className="feature-item">
            <h3 className="text-3xl font-semibold mb-4">CRM Integrado</h3>
            <p className="text-lg font-light">
              Controle atividades comerciais como ligações, reuniões e envio de tabelas para clientes com o módulo de CRM.
            </p>
          </div>
          <div className="feature-item">
            <h3 className="text-3xl font-semibold mb-4">Gestão de Manutenção</h3>
            <p className="text-lg font-light">
              Gerencie a frota e as manutenções periódicas dos veículos com eficiência.
            </p>
          </div>
        </div>
      </section>

      <section className="cta-section text-center py-16 bg-white">
        <h2 className="text-4xl font-semibold mb-6">Pronto para Transformar sua Gestão Logística?</h2>
        <a href="/signup" className="cta-button bg-blue-600 text-white py-3 px-6 rounded-lg text-lg hover:bg-blue-700">Cadastre-se Agora</a>
      </section>

      <footer className="footer py-8 text-center bg-gray-200">
        <p className="text-sm font-light text-gray-600">&copy; 2024 Hipervias. Todos os direitos reservados.</p>
      </footer>

      {/* Modal de Login */}
      <LoginModal isOpen={isModalOpen} onClose={toggleModal} triggerRef={loginButtonRef} />
    </div>
  );
};

export default LandingPage;
