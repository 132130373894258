import api from "../../utils/axiosConfig";
import cities from "../../json/cities.json";

// Função para obter o código da cidade
const getCityCode = (cityName) => {
  // Procura a cidade pelo nome no arquivo cities.json
  const city = cities.find((city) => city.name === cityName);
  // Retorna o código da cidade se encontrado, caso contrário retorna null
  return city ? city.City_Code : null;
};

// Função para calcular o frete
export const handleCalculateFreight = async (quoteDetails) => {
  try {
    // Obtém os códigos das cidades de origem e destino
    const cityCodeOrigin = getCityCode(quoteDetails.selectedPickupCity);
    const cityCodeDestination = getCityCode(quoteDetails.selectedDeliveryCity);

    // Verifica se os códigos das cidades foram encontrados
    if (!cityCodeOrigin || !cityCodeDestination) {
      throw new Error("Código das cidades não encontrado.");
    }

    // Loga a URL completa que está sendo chamada
    console.log("URL completa:", `${api.defaults.baseURL}/calculations/calculate-freight`);

    // Faz uma requisição GET para calcular o frete com os parâmetros necessários
    const response = await api.get("/calculations/calculate-freight", {
      params: {
        cityCodeOrigin,
        cityCodeDestination,
        peso: quoteDetails.peso,
        valor: quoteDetails.valor,
      },
    });

    // Loga os dados retornados do backend
    console.log("Dados calculados retornados do backend:", response.data);
    // Retorna os dados calculados
    return response.data;
  } catch (error) {
    // Loga o erro e lança uma exceção
    console.error("Erro ao calcular frete:", error);
    throw error;
  }
};

// Função para salvar a cotação inicial
export const handleSaveInitialQuote = async (quoteData) => {
  try {
    // Loga os dados da cotação inicial que serão salvos
    console.log("Salvando cotação inicial com dados:", quoteData);

    // Faz uma requisição POST para salvar a cotação inicial
    const response = await api.post("/quotes/initial", quoteData);

    // Loga a resposta do servidor
    console.log("Resposta do servidor:", response.data);
    // Retorna os dados da resposta
    return response.data;
  } catch (error) {
    // Loga o erro e lança uma exceção
    console.error("Erro ao salvar a cotação inicial:", error);
    throw error;
  }
};

// Função para atualizar o frete
export const handleUpdateFreight = async (id, selectionSection) => {
  try {
    // Loga o ID da cotação e os dados da seção de seleção que serão atualizados
    console.log("Atualizando cotação com ID:", id);
    console.log("Dados da seção de seleção recebidos:", selectionSection);

    // Faz uma requisição PUT para atualizar a cotação com o ID fornecido
    const response = await api.put(`/quotes/${id}`, selectionSection);

    // Loga a resposta do servidor
    console.log("Resposta do servidor:", response.data);
    // Retorna os dados da resposta
    return response.data;
  } catch (error) {
    // Loga o erro e lança uma exceção
    console.error("Erro ao atualizar a cotação:", error);
    throw error;
  }
};

// Função para deletar uma cotação por ID
export const handleDeleteQuote = async (quoteId) => {
  try {
    // Faz uma requisição DELETE para deletar a cotação com o ID fornecido
    const response = await api.delete(`/quotes/${quoteId}`);

    // Verifica se a resposta do servidor indica sucesso
    if (response.status === 200) {
      alert("Cotação deletada com sucesso!");
    } else {
      throw new Error("Erro ao deletar cotação.");
    }
  } catch (error) {
    // Loga o erro, exibe um alerta e lança uma exceção
    console.error("Erro ao deletar cotação:", error);
    alert("Erro ao deletar cotação. Por favor, tente novamente.");
    throw error;
  }
};

// Função para gerar uma venda
export const handleGenerateSale = async (quoteId) => {
  try {
    // Faz uma requisição PUT para gerar uma venda com o ID da cotação fornecido
    const response = await api.put(`/quotes/${quoteId}/generate-sale`);

    // Loga a resposta do servidor
    console.log("Resposta do servidor:", response.data);
    // Verifica se a resposta do servidor indica sucesso
    if (response.status === 200) {
      alert("Venda gerada com sucesso!");
    } else {
      throw new Error("Erro ao gerar venda.");
    }
  } catch (error) {
    // Loga o erro, exibe um alerta e lança uma exceção
    console.error("Erro ao gerar venda:", error);
    alert("Erro ao gerar venda. Por favor, tente novamente.");
    throw error;
  }
};