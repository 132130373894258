import { 
  fetchQuotes as fetchQuotesAPI, 
  createQuote as createQuoteAPI, 
  updateQuote as updateQuoteAPI 
} from '../../services/api';

// Função auxiliar para lidar com erros
const handleError = (dispatch, actionType, error) => {
  console.error(`Erro ao executar ação ${actionType}:`, error);
  dispatch({ type: actionType, payload: error.message });
};

// Ação para buscar cotações
export const fetchQuotes = () => async (dispatch) => {
  dispatch({ type: 'FETCH_QUOTES_REQUEST' });
  try {
    const data = await fetchQuotesAPI();
    if (process.env.NODE_ENV === 'development') {
      console.log('Fetched Quotes Data:', data);
    }
    dispatch({ type: 'FETCH_QUOTES_SUCCESS', payload: data });
  } catch (error) {
    handleError(dispatch, 'FETCH_QUOTES_FAILURE', error);
  }
};

// Ação para criar uma nova cotação
export const createQuoteAction = (quote) => async (dispatch) => {
  dispatch({ type: 'CREATE_QUOTE_REQUEST' });
  try {
    const data = await createQuoteAPI(quote);
    dispatch({ type: 'CREATE_QUOTE_SUCCESS', payload: data });
  } catch (error) {
    handleError(dispatch, 'CREATE_QUOTE_FAILURE', error);
  }
};

// Ação para atualizar uma cotação existente
export const updateQuoteAction = (id, quote) => async (dispatch) => {
  dispatch({ type: 'UPDATE_QUOTE_REQUEST' });
  try {
    const data = await updateQuoteAPI(id, quote);
    dispatch({ type: 'UPDATE_QUOTE_SUCCESS', payload: data });
  } catch (error) {
    handleError(dispatch, 'UPDATE_QUOTE_FAILURE', error);
  }
};
