import React from "react";
import clients from "../../json/clients.json";
import merchantstypes from "../../json/merchantstypes.json";
import { filterCities } from "../../utils/utils";

// Definição da função handleInputChange
const handleInputChange = (e, setFormData) => {
  const { name, value } = e.target;
  setFormData((prevData) => ({
    ...prevData,
    [name]: value,
  }));
};

const services = ["Frete Fracionado", "Frete Dedicado"];

const QuoteInputs = ({
  formData,
  setFormData,
  filteredPickupCities,
  filteredDeliveryCities,
  cities,
  setFilteredPickupCities,
  setFilteredDeliveryCities,
}) => (
  <div>
    <div className="quote-page__row">
      <div className="quote-page__group quote-page__group--half">
        <label className="quote-page__label">Cliente</label>
        <select
          name="selectedClient"
          value={formData.selectedClient}
          onChange={(e) => handleInputChange(e, setFormData)}
          className="quote-page__select"
        >
          <option value="">Selecione o cliente</option>
          {clients.map((client, index) => (
            <option key={index} value={client}>
              {client}
            </option>
          ))}
        </select>
      </div>

      <div className="quote-page__group quote-page__group--half">
        <label className="quote-page__label">Tipo de Serviço</label>
        <select
          name="selectedService"
          value={formData.selectedService}
          onChange={(e) => handleInputChange(e, setFormData)}
          className="quote-page__select"
        >
          <option value="">Selecione o serviço</option>
          {services.map((service, index) => (
            <option key={index} value={service}>
              {service}
            </option>
          ))}
        </select>
      </div>
    </div>

    <div className="quote-page__row">
      <div className="quote-page__group quote-page__group--half">
        <label className="quote-page__label">Local de Coleta</label>
        <input
          type="text"
          name="selectedPickupCity"
          value={formData.selectedPickupCity}
          onChange={(e) => {
            handleInputChange(e, setFormData);
            filterCities(e.target.value, cities, setFilteredPickupCities);
          }}
          className="quote-page__input"
          autoComplete="off"
          list="pickupCities"
        />
        <datalist id="pickupCities">
          {filteredPickupCities.map((city, index) => (
            <option key={index} value={city.name} />
          ))}
        </datalist>
      </div>

      <div className="quote-page__group quote-page__group--half">
        <label className="quote-page__label">Local de Entrega</label>
        <input
          type="text"
          name="selectedDeliveryCity"
          value={formData.selectedDeliveryCity}
          onChange={(e) => {
            handleInputChange(e, setFormData);
            filterCities(e.target.value, cities, setFilteredDeliveryCities);
          }}
          className="quote-page__input"
          autoComplete="off"
          list="deliveryCities"
        />
        <datalist id="deliveryCities">
          {filteredDeliveryCities.map((city, index) => (
            <option key={index} value={city.name} />
          ))}
        </datalist>
      </div>
    </div>

    <div className="quote-page__row">
      <div className="quote-page__group quote-page__group--quarter">
        <label className="quote-page__label">Peso (kg)</label>
        <input
          type="number"
          name="peso"
          value={formData.peso}
          onChange={(e) => handleInputChange(e, setFormData)}
          className="quote-page__input"
        />
      </div>

      <div className="quote-page__group quote-page__group--quarter">
        <label className="quote-page__label">Valor (R$)</label>
        <input
          type="number"
          name="valor"
          value={formData.valor}
          onChange={(e) => handleInputChange(e, setFormData)}
          className="quote-page__input"
        />
      </div>

      <div className="quote-page__group quote-page__group--quarter">
        <label className="quote-page__label">Volumes</label>
        <input
          type="number"
          name="volumes"
          value={formData.volumes}
          onChange={(e) => handleInputChange(e, setFormData)}
          className="quote-page__input"
        />
      </div>

      <div className="quote-page__group quote-page__group--quarter">
        <label className="quote-page__label">Mercadoria</label>
        <select
          name="selectedMerchandise"
          value={formData.selectedMerchandise}
          onChange={(e) => handleInputChange(e, setFormData)}
          className="quote-page__select"
        >
          <option value="">Selecione a mercadoria</option>
          {merchantstypes.map((type, index) => (
            <option key={index} value={type}>
              {type}
            </option>
          ))}
        </select>
      </div>
    </div>
  </div>
);

export default QuoteInputs;
