// Clientes
export const FETCH_CLIENTS_REQUEST = 'FETCH_CLIENTS_REQUEST';
export const FETCH_CLIENTS_SUCCESS = 'FETCH_CLIENTS_SUCCESS';
export const FETCH_CLIENTS_FAILURE = 'FETCH_CLIENTS_FAILURE';

export const CREATE_CLIENT_REQUEST = 'CREATE_CLIENT_REQUEST';
export const CREATE_CLIENT_SUCCESS = 'CREATE_CLIENT_SUCCESS';
export const CREATE_CLIENT_FAILURE = 'CREATE_CLIENT_FAILURE';

export const UPDATE_CLIENT_REQUEST = 'UPDATE_CLIENT_REQUEST';
export const UPDATE_CLIENT_SUCCESS = 'UPDATE_CLIENT_SUCCESS';
export const UPDATE_CLIENT_FAILURE = 'UPDATE_CLIENT_FAILURE';


// Cotações
export const FETCH_QUOTES_SUCCESS = 'FETCH_QUOTES_SUCCESS';
export const FETCH_QUOTES_FAILURE = 'FETCH_QUOTES_FAILURE';
export const FETCH_QUOTE_BY_ID_SUCCESS = 'FETCH_QUOTE_BY_ID_SUCCESS';
export const FETCH_QUOTE_BY_ID_FAILURE = 'FETCH_QUOTE_BY_ID_FAILURE';
export const CREATE_QUOTE_SUCCESS = 'CREATE_QUOTE_SUCCESS';
export const CREATE_QUOTE_FAILURE = 'CREATE_QUOTE_FAILURE';
export const UPDATE_QUOTE_SUCCESS = 'UPDATE_QUOTE_SUCCESS';
export const UPDATE_QUOTE_FAILURE = 'UPDATE_QUOTE_FAILURE';

// Vendas
export const FETCH_SALES_SUCCESS = 'FETCH_SALES_SUCCESS';
export const FETCH_SALES_FAILURE = 'FETCH_SALES_FAILURE';
export const CREATE_SALE_SUCCESS = 'CREATE_SALE_SUCCESS';
export const CREATE_SALE_FAILURE = 'CREATE_SALE_FAILURE';

// Viagens
export const FETCH_TRIPS_SUCCESS = 'FETCH_TRIPS_SUCCESS';
export const FETCH_TRIPS_FAILURE = 'FETCH_TRIPS_FAILURE';
export const CREATE_TRIP_SUCCESS = 'CREATE_TRIP_SUCCESS';
export const CREATE_TRIP_FAILURE = 'CREATE_TRIP_FAILURE';

// Login
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';

// Master Bills
export const FETCH_MASTER_BILLS_SUCCESS = 'FETCH_MASTER_BILLS_SUCCESS';
export const FETCH_MASTER_BILLS_FAILURE = 'FETCH_MASTER_BILLS_FAILURE';
export const CREATE_MASTER_BILL_SUCCESS = 'CREATE_MASTER_BILL_SUCCESS';
export const CREATE_MASTER_BILL_FAILURE = 'CREATE_MASTER_BILL_FAILURE';
export const UPDATE_MASTER_BILL_SUCCESS = 'UPDATE_MASTER_BILL_SUCCESS';
export const UPDATE_MASTER_BILL_FAILURE = 'UPDATE_MASTER_BILL_FAILURE';

// House Bills
export const FETCH_HOUSE_BILLS_SUCCESS = 'FETCH_HOUSE_BILLS_SUCCESS';
export const FETCH_HOUSE_BILLS_FAILURE = 'FETCH_HOUSE_BILLS_FAILURE';
export const CREATE_HOUSE_BILL_SUCCESS = 'CREATE_HOUSE_BILL_SUCCESS';
export const CREATE_HOUSE_BILL_FAILURE = 'CREATE_HOUSE_BILL_FAILURE';
export const UPDATE_HOUSE_BILL_SUCCESS = 'UPDATE_HOUSE_BILL_SUCCESS';
export const UPDATE_HOUSE_BILL_FAILURE = 'UPDATE_HOUSE_BILL_FAILURE';

// Manifests
export const FETCH_MANIFESTS_SUCCESS = 'FETCH_MANIFESTS_SUCCESS';
export const FETCH_MANIFESTS_FAILURE = 'FETCH_MANIFESTS_FAILURE';
export const CREATE_MANIFEST_SUCCESS = 'CREATE_MANIFEST_SUCCESS';
export const CREATE_MANIFEST_FAILURE = 'CREATE_MANIFEST_FAILURE';
export const UPDATE_MANIFEST_SUCCESS = 'UPDATE_MANIFEST_SUCCESS';
export const UPDATE_MANIFEST_FAILURE = 'UPDATE_MANIFEST_FAILURE';

// Definição dos tipos de ação para leads
export const FETCH_LEADS_REQUEST = 'FETCH_LEADS_REQUEST';
export const FETCH_LEADS_SUCCESS = 'FETCH_LEADS_SUCCESS';
export const FETCH_LEADS_FAILURE = 'FETCH_LEADS_FAILURE';

export const FETCH_LEAD_BY_ID_REQUEST = 'FETCH_LEAD_BY_ID_REQUEST';
export const FETCH_LEAD_BY_ID_SUCCESS = 'FETCH_LEAD_BY_ID_SUCCESS';
export const FETCH_LEAD_BY_ID_FAILURE = 'FETCH_LEAD_BY_ID_FAILURE';

export const CREATE_LEAD_REQUEST = 'CREATE_LEAD_REQUEST';
export const CREATE_LEAD_SUCCESS = 'CREATE_LEAD_SUCCESS';
export const CREATE_LEAD_FAILURE = 'CREATE_LEAD_FAILURE';

export const UPDATE_LEAD_REQUEST = 'UPDATE_LEAD_REQUEST';
export const UPDATE_LEAD_SUCCESS = 'UPDATE_LEAD_SUCCESS';
export const UPDATE_LEAD_FAILURE = 'UPDATE_LEAD_FAILURE';

export const DELETE_LEAD_REQUEST = 'DELETE_LEAD_REQUEST';
export const DELETE_LEAD_SUCCESS = 'DELETE_LEAD_SUCCESS';
export const DELETE_LEAD_FAILURE = 'DELETE_LEAD_FAILURE';

// Definição dos tipos de ação para tasks
export const FETCH_TASKS_REQUEST = 'FETCH_TASKS_REQUEST';
export const FETCH_TASKS_SUCCESS = 'FETCH_TASKS_SUCCESS';
export const FETCH_TASKS_FAILURE = 'FETCH_TASKS_FAILURE';

export const FETCH_TASK_BY_ID_REQUEST = 'FETCH_TASK_BY_ID_REQUEST';
export const FETCH_TASK_BY_ID_SUCCESS = 'FETCH_TASK_BY_ID_SUCCESS';
export const FETCH_TASK_BY_ID_FAILURE = 'FETCH_TASK_BY_ID_FAILURE';

export const CREATE_TASK_REQUEST = 'CREATE_TASK_REQUEST';
export const CREATE_TASK_SUCCESS = 'CREATE_TASK_SUCCESS';
export const CREATE_TASK_FAILURE = 'CREATE_TASK_FAILURE';

export const UPDATE_TASK_REQUEST = 'UPDATE_TASK_REQUEST';
export const UPDATE_TASK_SUCCESS = 'UPDATE_TASK_SUCCESS';
export const UPDATE_TASK_FAILURE = 'UPDATE_TASK_FAILURE';

export const DELETE_TASK_REQUEST = 'DELETE_TASK_REQUEST';
export const DELETE_TASK_SUCCESS = 'DELETE_TASK_SUCCESS';
export const DELETE_TASK_FAILURE = 'DELETE_TASK_FAILURE';