import React from 'react';
import '../assets/styles/index.css'; // Importando o CSS

const ContactsPage = () => {
  return (
    <div className="container">
      <h1 className="page-title">Contatos</h1>
      {/* Adicione aqui a lista ou formulário de contatos */}
    </div>
  );
};

export default ContactsPage;
