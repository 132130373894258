import { 
  fetchLeads as fetchLeadsAPI, 
  createLead as createLeadAPI, 
  updateLead as updateLeadAPI, 
  deleteLead as deleteLeadAPI 
} from '../../services/api';

// Função auxiliar para lidar com erros
const handleError = (dispatch, actionType, error) => {
  console.error(`Erro ao executar ação ${actionType}:`, error);
  dispatch({ type: actionType, payload: error.message });
};

// Ação para buscar leads
export const fetchLeads = () => async (dispatch) => {
  dispatch({ type: 'FETCH_LEADS_REQUEST' });
  try {
    const data = await fetchLeadsAPI();
    if (process.env.NODE_ENV === 'development') {
      console.log('Fetched Leads Data:', data);
    }
    dispatch({ type: 'FETCH_LEADS_SUCCESS', payload: data });
  } catch (error) {
    handleError(dispatch, 'FETCH_LEADS_FAILURE', error);
  }
};

// Ação para criar um novo lead
export const createLeadAction = (lead) => async (dispatch) => {
  dispatch({ type: 'CREATE_LEAD_REQUEST' });
  try {
    const data = await createLeadAPI(lead);
    if (process.env.NODE_ENV === 'development') {
      console.log('Created Lead Data:', data);
    }
    dispatch({ type: 'CREATE_LEAD_SUCCESS', payload: data });
  } catch (error) {
    handleError(dispatch, 'CREATE_LEAD_FAILURE', error);
  }
};

// Ação para atualizar um lead
export const updateLeadAction = (leadId, lead) => async (dispatch) => {
  dispatch({ type: 'UPDATE_LEAD_REQUEST' });
  try {
    const data = await updateLeadAPI(leadId, lead);
    if (process.env.NODE_ENV === 'development') {
      console.log('Updated Lead Data:', data);
    }
    dispatch({ type: 'UPDATE_LEAD_SUCCESS', payload: data });
  } catch (error) {
    handleError(dispatch, 'UPDATE_LEAD_FAILURE', error);
  }
};

// Ação para deletar um lead
export const deleteLeadAction = (leadId) => async (dispatch) => {
  dispatch({ type: 'DELETE_LEAD_REQUEST' });
  try {
    await deleteLeadAPI(leadId);
    if (process.env.NODE_ENV === 'development') {
      console.log('Deleted Lead ID:', leadId);
    }
    dispatch({ type: 'DELETE_LEAD_SUCCESS', payload: leadId });
  } catch (error) {
    handleError(dispatch, 'DELETE_LEAD_FAILURE', error);
  }
};