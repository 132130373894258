import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchClients } from "../store/actions/clientActions";
import { useNavigate } from "react-router-dom";
import "../assets/styles/dashboard.css";
import { FaEllipsisV } from "react-icons/fa"; // Importando a biblioteca de ícones

const ClientColumns = ({ activeView }) => {
  const columns = useMemo(() => {
    switch (activeView) {
      case "comercial":
        return ["Número do Cliente", "Nome", "CPF/CNPJ", "Status", "Limite de Crédito"];
      case "planejamento":
        return ["Data de Registro", "Cidade", "Estado", "Termos de Pagamento", "Risco de Crédito"];
      case "operacional":
        return ["Nome", "Rua", "Cidade", "Estado", "Telefone"];
      default:
        return [];
    }
  }, [activeView]);

  return (
    <>
      {columns.map((col, index) => (
        <div key={index}>{col}</div>
      ))}
    </>
  );
};

const ClientRows = ({ client, activeView, onActionClick }) => {
  const rows = useMemo(() => {
    switch (activeView) {
      case "comercial":
        const totalGeneral = client.financeSection?.creditLimit || 0;
        const totalClientValue = totalGeneral.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });

        return [
          client.logSection?.tenantClientNumber || "",
          client.registrationSection?.name || "",
          client.registrationSection?.CPF_CNPJ || "",
          client.logSection?.status || "",
          totalClientValue,
        ];
      case "planejamento":
        return [
          new Date(client.logSection?.registrationDate?.$date).toLocaleDateString() || "",
          client.registrationSection?.address?.city || "",
          client.registrationSection?.address?.state || "",
          client.financeSection?.paymentTerms || "",
          client.financeSection?.creditRisk || "",
        ];
      case "operacional":
        return [
          client.registrationSection?.name || "",
          client.registrationSection?.address?.street || "",
          client.registrationSection?.address?.city || "",
          client.registrationSection?.address?.state || "",
          client.registrationSection?.phone || "",
        ];
      default:
        return [];
    }
  }, [client, activeView]);

  return (
    <>
      {rows.map((row, index) => (
        <div className="quotes-list-item" key={index}>
          {row}
        </div>
      ))}
      <div className="quotes-list-item">
        <FaEllipsisV
          onClick={(event) => onActionClick(client, event)}
          className="action-icon"
        />
      </div>
    </>
  );
};

const ActionMenu = ({ client, navigate, onClose }) => {
  const handleEdit = useCallback(() => {
    // Implementar a lógica de edição do cliente
    onClose();
  }, [client._id, navigate, onClose]);

  const handlePDF = useCallback(() => {
    // Implementar a lógica de geração de PDF do cliente
    onClose();
  }, [client._id, onClose]);

  const handleCopy = useCallback(() => {
    // Implementar a lógica de cópia do cliente
    onClose();
  }, [client._id, onClose]);

  const handleEmail = useCallback(() => {
    // Implementar a lógica de envio por email do cliente
    onClose();
  }, [client._id, onClose]);

  const handleSale = useCallback(() => {
    // Implementar a lógica de geração de venda do cliente
    onClose();
  }, [client._id, onClose]);

  return (
    <div className="action-menu-container">
      <ul className="action-menu-list">
        <li onClick={handleEdit}>Editar</li>
        <li onClick={handlePDF}>Gerar PDF</li>
        <li onClick={handleCopy}>Copiar</li>
        <li onClick={handleEmail}>Enviar por Email</li>
        <li onClick={handleSale}>Gerar Venda</li>
      </ul>
    </div>
  );
};

const ClientsBoard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { clients, loading, error } = useSelector((state) => state.clients);
  const [activeView, setActiveView] = useState("comercial");
  const [selectedClient, setSelectedClient] = useState(null);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    dispatch(fetchClients());
  }, [dispatch]);

  const handleViewChange = useCallback((view) => {
    setActiveView(view);
  }, []);

  const handleActionClick = useCallback((client, event) => {
    if (event && event.target) {
      const rect = event.target.getBoundingClientRect();
      setMenuPosition({ top: rect.bottom, left: rect.left });
      setSelectedClient(client);
    }
  }, []);

  const handleCloseMenu = useCallback(() => {
    setSelectedClient(null);
  }, []);

  const handleCreateClient = useCallback(() => {
    navigate("/client");
  }, [navigate]);

  // Ordenar os clientes em ordem decrescente pelo número do cliente
  const sortedClients = useMemo(() => {
    return clients.slice().sort((a, b) => {
      const numA = a.logSection?.tenantClientNumber || 0;
      const numB = b.logSection?.tenantClientNumber || 0;
      return numB - numA;
    });
  }, [clients]);

  if (loading) return <p>Carregando...</p>;
  if (error) return <p>Erro ao carregar clientes: {error.message}</p>;

  return (
    <div className="dashboard-container">
      <h1>Painel de Clientes</h1>
      <div className="view-buttons">
        <button onClick={() => handleViewChange("comercial")}>
          Visão Comercial
        </button>
        <button onClick={() => handleViewChange("planejamento")}>
          Visão Planejamento
        </button>
        <button onClick={() => handleViewChange("operacional")}>
          Visão Operacional
        </button>
        <button onClick={handleCreateClient} className="create-quote-button">
          Criar Cliente
        </button>
      </div>

      <div className="quotes-list header">
        <ClientColumns activeView={activeView} />
      </div>

      {sortedClients.map((client) => (
        <div className="quotes-list" key={client._id}>
          <ClientRows
            client={client}
            activeView={activeView}
            onActionClick={handleActionClick}
          />
        </div>
      ))}

      {selectedClient && (
        <div
          className="action-menu-container"
          style={{ top: menuPosition.top, left: menuPosition.left }}
        >
          <ActionMenu
            client={selectedClient}
            navigate={navigate}
            onClose={handleCloseMenu}
          />
        </div>
      )}
    </div>
  );
};

export default ClientsBoard;