// Dicionário de traduções
const dictionaries = {
  fiorino_450kg_fcl: "Furgão 450kg",
  vuc_1500kg_fcl: "VUC 1.500kg",
  caminhao_4000kg_fcl: "Caminhão 4.000kg",
  caminhao_7000kg_fcl: "Caminhão Toco 7.000kg",
  caminhao_15000_fcl: "Caminhão 15.000kg",
  carreta_27000_fcl: "Carreta 27.000kg",
  scheduling_fee: "Agendamento",
  diffuculty_fee: "Taxa de Dificuldade",
  unloading_helper: "Ajudante",
  fork_lift: "Empilhadeira",
  palletization: "Paletização",
  packaging: "Embalagem",
  strech_film: "Filme Stretch",
  regular: "Normal",
  expedited: "Urgente",
  economic: "Econômico",
  optionalServicesFor: "Serviços Opcionais para",
  closeModal: "Fechar modal",
  optionalServicesOrigin: "Serviços Opcionais de Origem",
  noOptionalServicesOrigin: "Nenhum serviço opcional de origem disponível.",
  optionalServicesDestination: "Serviços Opcionais de Destino",
  noOptionalServicesDestination: "Nenhum serviço opcional de destino disponível.",
  cancel: "Cancelar",
  save: "Salvar",
  // Termos de ClientPage
  type: "Tipo",
  name: "Nome",
  CPF_CNPJ: "CPF/CNPJ",
  RG_IE: "RG/IE",
  email: "Email",
  phone: "Telefone",
  street: "Rua",
  number: "Número",
  complement: "Complemento",
  district: "Bairro",
  city: "Cidade",
  state: "Estado",
  zip: "CEP",
  city_code: "Código da Cidade",
  creditLimit: "Limite de Crédito",
  paymentTerms: "Termos de Pagamento",
  creditRisk: "Risco de Crédito",
  notes: "Notas",
  main: "Principal",
  registrationSection: "Dados Cadastrais",
  address: "Endereço",
  contactSection: "Contato",
  financeSection: "Ficha Financeira",
  submit_client: "Cadastrar Cliente",
  position: "Cargo",
  addContact: "Adicionar Contato",
  removeContact: "Remover Contato",
  submit_lead: "Cadastrar Oportunidade",
  submit: "Cadastrar",
  selectCity: "Selecione uma cidade",
  home: "Home",
  commercial: "Comercial",
  quotes: "Cotações",
  create_quote: "Criar Cotação",
  crm: "CRM",
  leads_board: "Painel de Oportunidades",
  tasks_board: "Painel de Tarefas",
  create_task: "Criar Tarefa",
  operations: "Operações",
  house_bill: "House Bill",
  master_bill: "Master Bill",
  manifest: "Manifesto",
  maintenance: "Manutenção",
  financial: "Financeiro",
  invoicing: "Faturamento",
  accounts_to_pay: "Contas a Pagar",
  registrations: "Cadastros",
  clients: "Clientes",
  contacts: "Contatos",
  collaborators: "Colaboradores",
  users: "Usuários",
  logout: "Sair",
  quote_board: 'Painel de Cotações',
  lead_board: 'Painel de Oportunidades',
  create_lead: 'Criar Oportunidade',
  task_board: 'Painel de Tarefas',
};

// Função genérica que retorna a tradução ou a chave formatada se não encontrada
export const translate = (key) => {
  if (typeof key !== "string") {
    return "Termo desconhecido"; // Fallback para quando a chave não é uma string válida
  }

  // Retorna a tradução ou uma formatação padrão (substituindo underscores por espaços)
  return dictionaries[key] || key.replace(/_/g, " ");
};