import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loginUser } from '../store/actions/authActions';

// Componente de modal de login
const LoginModal = ({ isOpen, onClose }) => {
  // Estado para armazenar os dados do formulário (email e senha)
  const [formData, setFormData] = useState({ email: '', password: '' });
  // Estado para armazenar os erros de validação
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Função que atualiza os dados do formulário quando o usuário digita
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Função que valida os campos do formulário
  const validate = () => {
    const newErrors = {};
    if (!formData.email) newErrors.email = 'Email é obrigatório';
    if (!formData.password) newErrors.password = 'Senha é obrigatória';
    return newErrors;
  };

  // Função que envia o formulário de login
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        await dispatch(loginUser(formData));
        navigate('/home'); // Redireciona para a página inicial após login
        onClose(); // Fecha o modal após login
      } catch (error) {
        setErrors({ form: 'Falha no login. Tente novamente.' });
      }
    }
  };

  // Se o modal não estiver aberto, não renderiza nada
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div
  className="modal bg-white p-4 rounded-lg shadow-lg flex flex-col items-center" // Ajuste o padding aqui
  style={{
    position: "fixed",
    top: "33.4%",
    left: "88.9%",
    transform: "translate(-50%, -50%)",
    width: "279px",
    maxWidth: "calc(100% - 40px)", // Ajusta a largura para caber na tela
    height: "600px", // Defina a altura específica aqui, se necessário
  }}
>
  <h2 className="modal__title text-4xl font-semibold mb-4 text-center">Login</h2>
  <form onSubmit={handleSubmit} className="w-full">
    <div className="mb-6">
      <label className="block text-sm font-semibold text-black-700">Email</label>
      <input
        type="email"
        name="email"
        value={formData.email}
        onChange={handleChange}
        className="w-full p-2 border border-gray-300 rounded-lg"
        placeholder="Digite seu email"
        required
      />
      {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
    </div>
    <div className="mb-6">
      <label className="block text-sm font-semibold text-black-700">Senha</label>
      <input
        type="password"
        name="password"
        value={formData.password}
        onChange={handleChange}
        className="w-full p-2 border border-gray-300 rounded-lg"
        placeholder="Digite sua senha"
        required
      />
      {errors.password && <p className="text-red-500 text-sm">{errors.password}</p>}
    </div>
    {errors.form && <p className="text-red-500 text-sm">{errors.form}</p>}
    <button
      type="submit"
      className="w-full bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-700"
    >
      Entrar
    </button>
  </form>
  <button
    onClick={onClose}
    className="mt-4 text-black-500 hover:underline"
  >
    Fechar
  </button>
</div>
    </div>
  );
};

export default LoginModal;