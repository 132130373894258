import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { terms } from './quoteTerms';
import { fetchTenantInfoById, fetchQuoteById } from '../../services/api';

// Função para editar a cotação
export const handleEditQuote = (quoteId, navigate) => {
  navigate(`/quote/${quoteId}`);
};

// Função para copiar a cotação para a área de transferência
export const handleCopyToClipboard = async (quoteId) => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error("Token não encontrado. Por favor, faça login novamente.");
    }

    const quoteData = await fetchQuoteById(quoteId, token);
    if (!quoteData) {
      throw new Error('Dados da cotação não encontrados.');
    }

    const tenantId = quoteData.inputSection.tenantId;
    const tenantInfo = await fetchTenantInfoById(tenantId, token);
    if (!tenantInfo) {
      throw new Error('Informações do tenant não encontradas.');
    }

    const selectionSection = quoteData.selectionSection || {};
    const selectedOption = selectionSection.selectedOption || {};

    const clipboardText = `
Prezado Cliente,

Segue abaixo os detalhes da sua cotação:

Cliente: ${quoteData.inputSection.selectedClient || 'N/A'}
Serviço: ${quoteData.inputSection.selectedService || 'N/A'}
Origem: ${quoteData.inputSection.selectedPickupCity || 'N/A'}
Destino: ${quoteData.inputSection.selectedDeliveryCity || 'N/A'}
Peso: ${quoteData.inputSection.peso || 'N/A'} kg
Volumes: ${quoteData.inputSection.volumes || 'N/A'}
Mercadoria: ${quoteData.inputSection.selectedMerchandise || 'N/A'}
Tipo de Entrega: ${selectedOption.label || 'N/A'}
Prazo de Entrega: ${selectedOption.eta || 'N/A'}
Total de Serviços (R$): ${selectionSection.totalAndTaxes?.totalServices !== undefined ? Number(selectionSection.totalAndTaxes.totalServices).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'N/A'}
ICMS (R$): ${selectionSection.totalAndTaxes?.icms !== undefined ? Number(selectionSection.totalAndTaxes.icms).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'N/A'}
Total Geral (R$): ${selectionSection.totalAndTaxes?.totalGeneral !== undefined ? Number(selectionSection.totalAndTaxes.totalGeneral).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'N/A'}

Atenciosamente,
${tenantInfo.nome}
    `;

    await navigator.clipboard.writeText(clipboardText.trim());
    alert("Cotação copiada para a área de transferência!");
  } catch (error) {
    console.error("Erro ao copiar a cotação:", error);
    alert("Erro ao copiar a cotação. Por favor, tente novamente.");
  }
};

// Função para enviar a cotação por email
export const handleSendByEmail = async (quoteId) => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error("Token não encontrado. Por favor, faça login novamente.");
    }

    const quoteData = await fetchQuoteById(quoteId, token);
    if (!quoteData) {
      throw new Error('Dados da cotação não encontrados.');
    }

    const tenantId = quoteData.inputSection.tenantId;
    const tenantInfo = await fetchTenantInfoById(tenantId, token);
    if (!tenantInfo) {
      throw new Error('Informações do tenant não encontradas.');
    }

    const selectionSection = quoteData.selectionSection || {};
    const selectedOption = selectionSection.selectedOption || {};

    const emailBody = `
Prezado Cliente,

Segue abaixo os detalhes da sua cotação:

Cliente: ${quoteData.inputSection.selectedClient || 'N/A'}
Serviço: ${quoteData.inputSection.selectedService || 'N/A'}
Origem: ${quoteData.inputSection.selectedPickupCity || 'N/A'}
Destino: ${quoteData.inputSection.selectedDeliveryCity || 'N/A'}
Peso: ${quoteData.inputSection.peso || 'N/A'} kg
Volumes: ${quoteData.inputSection.volumes || 'N/A'}
Mercadoria: ${quoteData.inputSection.selectedMerchandise || 'N/A'}
Tipo de Entrega: ${selectedOption.label || 'N/A'}
Prazo de Entrega: ${selectedOption.eta || 'N/A'}
Total de Serviços (R$): ${selectionSection.totalAndTaxes?.totalServices !== undefined ? Number(selectionSection.totalAndTaxes.totalServices).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'N/A'}
ICMS (R$): ${selectionSection.totalAndTaxes?.icms !== undefined ? Number(selectionSection.totalAndTaxes.icms).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'N/A'}
Total Geral (R$): ${selectionSection.totalAndTaxes?.totalGeneral !== undefined ? Number(selectionSection.totalAndTaxes.totalGeneral).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'N/A'}

Atenciosamente,
${tenantInfo.nome}
    `;

    window.location = `mailto:${quoteData.inputSection.selectedClient}?subject=Cotação&body=${encodeURIComponent(emailBody.trim())}`;
  } catch (error) {
    console.error("Erro ao enviar email:", error);
    alert("Erro ao enviar email. Por favor, tente novamente.");
  }
};

// Função para gerar o PDF da cotação
export const handleGeneratePDF = async (quoteId) => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error("Token não encontrado. Por favor, faça login novamente.");
    }

    const quoteData = await fetchQuoteById(quoteId, token);
    if (!quoteData) {
      throw new Error('Dados da cotação não encontrados.');
    }

    const tenantId = quoteData.inputSection.tenantId;
    const tenantInfo = await fetchTenantInfoById(tenantId, token);
    if (!tenantInfo) {
      throw new Error('Informações do tenant não encontradas.');
    }

    console.log("Dados da cotação:", quoteData);
    console.log("Informações do tenant:", tenantInfo);

    const doc = new jsPDF({
      format: 'a4',
      unit: 'mm',
    });

    const addFooter = (doc) => {
      const pageCount = doc.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        doc.setFontSize(10);
        doc.text('www.hipervias.com.br', 105, 290, { align: 'center' });
      }
    };

    doc.setFontSize(16);
    doc.text("Cotação de Transporte", 105, 10, { align: 'center' });

    const tenantQuoteNumber = quoteData.logSection?.tenantQuoteNumber || 'N/A';

    const tenantInfoData = [
      ["Número da Cotação", tenantQuoteNumber],
      ["Prestadora de Serviço", tenantInfo.nome],
      ["CNPJ", tenantInfo.cnpj],
      ["Endereço", `${tenantInfo.endereco}, ${tenantInfo.bairro}`],
      ["Cidade", `${tenantInfo.cidade} - ${tenantInfo.uf}`],
      ["CEP", tenantInfo.cep],
      ["Email", tenantInfo.email],
      ["Telefone", tenantInfo.telefone],
    ];

    doc.autoTable({
      head: [tenantInfoData.map(item => item[0])],
      body: [tenantInfoData.map(item => item[1])],
      startY: 20,
      theme: 'grid',
      styles: { fontSize: 10 },
    });

    const inputData = [
      ["Cliente", quoteData.inputSection.selectedClient || ''],
      ["Serviço", quoteData.inputSection.selectedService || ''],
      ["Origem", quoteData.inputSection.selectedPickupCity || ''],
      ["Destino", quoteData.inputSection.selectedDeliveryCity || ''],
      ["Peso (kg)", quoteData.inputSection.peso || ''],
      ["Valor (R$)", quoteData.inputSection.valor !== undefined ? Number(quoteData.inputSection.valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ''],
      ["Volumes", quoteData.inputSection.volumes || ''],
      ["Mercadoria", quoteData.inputSection.selectedMerchandise || ''],
    ];

    console.log("Dados de entrada da cotação:", inputData);

    doc.autoTable({
      head: [inputData.map(item => item[0])],
      body: [inputData.map(item => item[1])],
      startY: doc.autoTable.previous.finalY + 5,
      theme: 'grid',
      styles: { fontSize: 10 },
    });

    const selectionSection = quoteData.selectionSection || {};
    const selectedOption = selectionSection.selectedOption || {};

    const salesData = [
      ["Operador", selectedOption?.operator || 'N/A'],
      ["Tipo de Entrega", selectedOption?.label || 'N/A'],
      ["Prazo", selectedOption?.eta || 'N/A'],
      ["Total de Serviços (R$)", selectionSection?.totalAndTaxes?.totalServices !== undefined ? Number(selectionSection.totalAndTaxes.totalServices).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'N/A'],
      ["ICMS (R$)", selectionSection?.totalAndTaxes?.icms !== undefined ? Number(selectionSection.totalAndTaxes.icms).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'N/A'],
      ["Total Geral (R$)", selectionSection?.totalAndTaxes?.totalGeneral !== undefined ? Number(selectionSection.totalAndTaxes.totalGeneral).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'N/A']
    ];

    console.log("Dados de vendas:", salesData);

    doc.autoTable({
      head: [salesData.map(item => item[0])],
      body: [salesData.map(item => item[1])],
      startY: doc.autoTable.previous.finalY + 5,
      theme: 'grid',
      styles: { fontSize: 10 },
    });

    doc.text("Termos e Condições", 10, doc.autoTable.previous.finalY + 10);
    doc.setFontSize(8);

    let termsPositionY = doc.autoTable.previous.finalY + 16;
    const termsLines = doc.splitTextToSize(terms, 190);
    termsLines.forEach((line, index) => {
      if (termsPositionY + 10 > 277) {
        doc.addPage();
        termsPositionY = 10;
      }
      doc.text(line, 10, termsPositionY);
      termsPositionY += 6;
    });

    addFooter(doc);

    const formattedDate = new Date(quoteData.updatedAt).toLocaleDateString('pt-BR').replace(/\//g, '-');
    const fileName = `Cotacao_${tenantQuoteNumber}_${tenantInfo.nome}_${formattedDate}.pdf`;

    doc.save(fileName);
  } catch (error) {
    console.error("Erro ao gerar PDF:", error);
    alert("Erro ao gerar PDF. Por favor, tente novamente.");
  }
};