import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Provider, useSelector } from 'react-redux';
import store from './store';
import Layout from './components/Layout';
import LandingPage from './pages/LandingPage';
import HomePage from './pages/HomePage';
import HouseBillPage from './pages/HouseBillPage';
import MasterBillPage from './pages/MasterBillPage';
import ManifestPage from './pages/ManifestPage';
import MaintenancePage from './pages/MaintenancePage';
import ClientPage from './pages/ClientPage';
import ClientsBoard from './pages/ClientsBoard';
import ContactsPage from './pages/ContactsPage';
import CollaboratorsPage from './pages/CollaboratorsPage';
import UsersPage from './pages/UsersPage';
import QuotesBoard from './pages/QuotesBoard';
import QuotePage from './pages/QuotePage';
import LeadsBoard from './pages/LeadsBoard';
import LeadPage from './pages/LeadPage';
import TaskPage from './pages/TaskPage'; // Adicionando TaskPage
import TasksBoard from './pages/TaskBoard'; // Adicionando TasksBoard

// Rotas privadas que requerem autenticação
const PrivateRoutes = () => (
  <Layout>
    <Routes>
      <Route path="/housebill" element={<HouseBillPage />} />
      <Route path="/masterbill" element={<MasterBillPage />} />
      <Route path="/manifest" element={<ManifestPage />} />
      <Route path="/maintenance" element={<MaintenancePage />} />
      <Route path="/client" element={<ClientPage />} />
      <Route path="/clients" element={<ClientsBoard />} />
      <Route path="/contacts" element={<ContactsPage />} />
      <Route path="/collaborators" element={<CollaboratorsPage />} />
      <Route path="/users" element={<UsersPage />} />
      <Route path="/quotes" element={<QuotesBoard />} />
      <Route path="/quote" element={<QuotePage />} />
      <Route path="/quote/:id" element={<QuotePage />} /> {/* Rota dinâmica para editar cotação */}
      <Route path="/leads" element={<LeadsBoard />} />
      <Route path="/lead" element={<LeadPage />} />
      <Route path="/lead/:id" element={<LeadPage />} /> {/* Rota dinâmica para editar lead */}
      <Route path="/tasks" element={<TasksBoard />} /> {/* Adicionando rota para TasksBoard */}
      <Route path="/task" element={<TaskPage />} /> {/* Adicionando rota para TaskPage */}
      <Route path="/task/:id" element={<TaskPage />} /> {/* Rota dinâmica para editar tarefa */}
      <Route path="*" element={<Navigate to="/home" />} /> {/* Redirecionamento para Home se rota inválida */}
    </Routes>
  </Layout>
);

const App = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/" element={isAuthenticated ? <Navigate to="/home" /> : <LandingPage />} />
          <Route path="/home" element={isAuthenticated ? <HomePage /> : <Navigate to="/" />} />
          {isAuthenticated ? (
            <Route path="*" element={<PrivateRoutes />} />
          ) : (
            <Route path="*" element={<Navigate to="/" />} />
          )}
        </Routes>
      </Router>
    </Provider>
  );
};

export default App;