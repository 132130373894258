import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchQuotes } from "../store/actions/quoteActions";
import { useNavigate } from "react-router-dom";
import {
  handleEditQuote,
  handleGeneratePDF,
  handleCopyToClipboard,
  handleSendByEmail,
} from "../components/quoteComponents/quoteExports";
import { handleGenerateSale } from "../components/quoteComponents/quoteApi";
import "../assets/styles/dashboard.css";
import { FaEllipsisV } from "react-icons/fa"; // Importando a biblioteca de ícones

const QuoteColumns = ({ activeView }) => {
  const columns = useMemo(() => {
    switch (activeView) {
      case "comercial":
        return [
          "Número da Cotação",
          "Cliente",
          "Serviço",
          "Status",
          "Valor Total",
        ];
      case "planejamento":
        return [
          "Data de Criação",
          "Origem",
          "Destino",
          "Prazo",
          "Tipo de Serviço",
        ];
      case "operacional":
        return ["Cliente", "Origem", "Destino", "Peso", "Volumes"];
      default:
        return [];
    }
  }, [activeView]);

  return (
    <>
      {columns.map((col, index) => (
        <div key={index}>{col}</div>
      ))}
    </>
  );
};

const QuoteRows = ({ quote, activeView, onActionClick }) => {
  const rows = useMemo(() => {
    switch (activeView) {
      case "comercial":
        const totalGeneral = Number(
          quote.selectionSection?.totalAndTaxes?.totalGeneral || 0
        );
        const totalQuoteValue = totalGeneral.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });

        return [
          quote.logSection?.tenantQuoteNumber,
          quote.inputSection?.selectedClient,
          quote.inputSection?.selectedService,
          quote.logSection?.status,
          totalQuoteValue,
        ];
      case "planejamento":
        return [
          new Date(quote.createdAt).toLocaleDateString(),
          quote.routeSection?.originCity?.immediate_name,
          quote.routeSection?.destinationCity?.immediate_name,
          quote.selectionSection?.selectedOption?.eta,
          quote.inputSection?.selectedService,
        ];
      case "operacional":
        return [
          quote.inputSection?.selectedClient,
          quote.routeSection?.originCity?.immediate_name,
          quote.routeSection?.destinationCity?.immediate_name,
          quote.inputSection?.peso,
          quote.inputSection?.volumes,
        ];
      default:
        return [];
    }
  }, [quote, activeView]);

  return (
    <>
      {rows.map((row, index) => (
        <div className="quotes-list-item" key={index}>
          {row}
        </div>
      ))}
      <div className="quotes-list-item">
        <FaEllipsisV
          onClick={(event) => onActionClick(quote, event)}
          className="action-icon"
        />
      </div>
    </>
  );
};

const ActionMenu = ({ quote, navigate, onClose }) => {
  const handleEdit = useCallback(() => {
    handleEditQuote(quote._id, navigate);
    onClose();
  }, [quote._id, navigate, onClose]);

  const handlePDF = useCallback(() => {
    handleGeneratePDF(quote._id);
    onClose();
  }, [quote._id, onClose]);

  const handleCopy = useCallback(() => {
    handleCopyToClipboard(quote._id);
    onClose();
  }, [quote._id, onClose]);

  const handleEmail = useCallback(() => {
    handleSendByEmail(quote._id);
    onClose();
  }, [quote._id, onClose]);

  const handleSale = useCallback(() => {
    handleGenerateSale(quote._id);
    onClose();
  }, [quote._id, onClose]);

  return (
    <div className="action-menu-container">
      <ul className="action-menu-list">
        <li onClick={handleEdit}>Editar</li>
        <li onClick={handlePDF}>Gerar PDF</li>
        <li onClick={handleCopy}>Copiar</li>
        <li onClick={handleEmail}>Enviar por Email</li>
        <li onClick={handleSale}>Gerar Venda</li>
      </ul>
    </div>
  );
};

const QuoteBoard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { quotes, loading, error } = useSelector((state) => state.quotes);
  const [activeView, setActiveView] = useState("comercial");
  const [selectedQuote, setSelectedQuote] = useState(null);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    dispatch(fetchQuotes());
  }, [dispatch]);

  const handleViewChange = useCallback((view) => {
    setActiveView(view);
  }, []);

  const handleActionClick = useCallback((quote, event) => {
    if (event && event.target) {
      const rect = event.target.getBoundingClientRect();
      setMenuPosition({ top: rect.bottom, left: rect.left });
      setSelectedQuote(quote);
    }
  }, []);

  const handleCloseMenu = useCallback(() => {
    setSelectedQuote(null);
  }, []);

  const handleCreateQuote = useCallback(() => {
    navigate("/quote");
  }, [navigate]);

  // Ordenar as cotações em ordem decrescente pelo número da cotação
  const sortedQuotes = useMemo(() => {
    return quotes.slice().sort((a, b) => {
      const numA = a.logSection?.tenantQuoteNumber || 0;
      const numB = b.logSection?.tenantQuoteNumber || 0;
      return numB - numA;
    });
  }, [quotes]);

  if (loading) return <p>Carregando...</p>;
  if (error) return <p>Erro ao carregar cotações: {error.message}</p>;

  return (
    <div className="dashboard-container">
      <h1>Painel de Cotações</h1>
      <div className="view-buttons">
        <button onClick={() => handleViewChange("comercial")}>
          Visão Comercial
        </button>
        <button onClick={() => handleViewChange("planejamento")}>
          Visão Planejamento
        </button>
        <button onClick={() => handleViewChange("operacional")}>
          Visão Operacional
        </button>
        <button onClick={handleCreateQuote} className="create-quote-button">
          Criar Cotação
        </button>
      </div>

      <div className="quotes-list header">
        <QuoteColumns activeView={activeView} />
      </div>

      {sortedQuotes.map((quote) => (
        <div className="quotes-list" key={quote._id}>
          <QuoteRows
            quote={quote}
            activeView={activeView}
            onActionClick={handleActionClick}
          />
        </div>
      ))}

      {selectedQuote && (
        <div
          className="action-menu-container"
          style={{ top: menuPosition.top, left: menuPosition.left }}
        >
          <ActionMenu
            quote={selectedQuote}
            navigate={navigate}
            onClose={handleCloseMenu}
          />
        </div>
      )}
    </div>
  );
};

export default QuoteBoard;
