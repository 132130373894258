import React from "react";
import "../../assets/styles/table.css";
import { translate } from "../Dictionary";
import {
  renderServiceSection,
  renderSelectedService,
  renderFeeSummary,
  renderLocalServiceSection,
  renderTotalAndTaxes,
  getTollValue,
  renderOptionalServices,
} from "./quoteRender";

// Função para calcular o custo total
export const calculateTotalCost = (
  formData,
  salesSummary,
  selectedOption,
  totalOptionalFees
) => {
  // Obtém as seções de serviços locais e resumo de taxas do resumo de vendas
  const localServiceSection = salesSummary?.localServiceSection || {};
  const feeSummary = salesSummary?.feeSummary || {};

  // Calcula o valor do pedágio
  const tollValue = getTollValue(
    formData.selectedService,
    selectedOption?.label,
    feeSummary
  );

  // Calcula o custo total dos serviços
  const totalServices =
    parseFloat(selectedOption?.totalCost || 0) +
    parseFloat(tollValue) +
    parseFloat(feeSummary?.adValorem || 0) +
    parseFloat(feeSummary?.riskManagement || 0) +
    parseFloat(feeSummary?.suframa || 0) +
    parseFloat(localServiceSection?.pickupCost || 0) +
    parseFloat(localServiceSection?.deliveryCost || 0) +
    totalOptionalFees;

  // Calcula o ICMS
  const icms = (totalServices / 0.88) * 0.12;

  // Retorna o custo total, ICMS e o total geral
  return {
    totalServices,
    icms,
    totalGeneral: totalServices + icms,
    totalAndTaxes: {
      totalServices,
      icms,
      totalGeneral: totalServices + icms,
    },
  };
};

const QuoteResults = ({
  formData,
  salesSummary,
  handleOptionClick,
  selectedOption,
  optionalServicesSelected = {},
  optionalServices = [],
  deselectSelectedOption,
}) => {
  // Função para formatar valores monetários
  const formatCurrency = (value) =>
    new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(parseFloat(value) || 0);

  // Função para obter todas as opções de serviços
  const getAllOptions = () => {
    const { serviceSection } = salesSummary || {};

    if (!serviceSection || !formData.selectedService) return [];

    const serviceType =
      formData.selectedService.toLowerCase() === "frete fracionado"
        ? "fracionado"
        : "dedicado";

    const allOptions = [];

    if (serviceType === "fracionado") {
      serviceSection.fracionado?.options?.forEach((option) => {
        allOptions.push({
          index: option.index,
          label: translate(option.type),
          eta: `${option.eta} dias`,
          totalCost: option.chargePerWeight,
          service: "Fracionado",
          operator: serviceSection.fracionado.operatorMain,
        });
      });
    } else if (serviceType === "dedicado") {
      Object.keys(serviceSection.dedicado || {}).forEach((vehicle) => {
        allOptions.push({
          index: serviceSection.dedicado[vehicle].index,
          label: translate(vehicle),
          eta: `${serviceSection.dedicado[vehicle].eta} dias`,
          totalCost: serviceSection.dedicado[vehicle].chargePerWeight,
          service: "Dedicado",
          operator: "999",
        });
      });
    }

    return allOptions;
  };

  // Calcula o total das taxas opcionais
  const totalOptionalFees = optionalServices
    .filter((service) => optionalServicesSelected[service.id])
    .reduce((total, service) => total + service.value, 0);

  // Calcula o custo total, ICMS e total geral
  const { totalServices, icms, totalGeneral } =
    calculateTotalCost(
      formData,
      salesSummary,
      selectedOption,
      totalOptionalFees
    );

  // Função para renderizar os serviços selecionados
  const renderSelectedServices = (services, selected) => {
    return services
      .filter((service) => selected[service.id])
      .map((service) => (
        <div key={service.id}>
          {`${translate(service.name)} - ${formatCurrency(service.value)}`}
          <button onClick={() => deselectSelectedOption(service.id)}>Deselecionar</button>
        </div>
      ));
  };

  // Filtra os serviços opcionais de origem e destino selecionados
  const selectedOriginServices = optionalServices.filter((service) =>
    service.id.startsWith("origin-")
  ).filter((service) => optionalServicesSelected[service.id]);

  const selectedDestinationServices = optionalServices.filter((service) =>
    service.id.startsWith("destination-")
  ).filter((service) => optionalServicesSelected[service.id]);

  // Renderiza o componente QuoteResults
  return (
    <div className="quote-page__group quote-page__group--full">
      {renderServiceSection(
        getAllOptions(),
        selectedOption,
        handleOptionClick,
        formatCurrency
      )}
      {selectedOption && (
        <div>{renderSelectedService(selectedOption, formatCurrency)}</div>
      )}
      {renderFeeSummary(
        formData.selectedService,
        selectedOption?.label,
        salesSummary?.feeSummary,
        formatCurrency
      )}
      {renderLocalServiceSection(
        salesSummary?.localServiceSection,
        formatCurrency
      )}
      {renderOptionalServices(
        selectedOriginServices,
        selectedDestinationServices,
        totalOptionalFees,
        formatCurrency
      )}
      {renderTotalAndTaxes(totalServices, icms, totalGeneral, formatCurrency)}
    </div>
  );
};

export default QuoteResults;