import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import "../../assets/styles/modal.css";
import { translate } from "../Dictionary";

const OptionalServicesModal = ({
  services = [],
  selectedFees,
  toggleFee,
  title,
  isOpen,
  onRequestClose,
  onSave,
}) => {
  const [loading, setLoading] = useState(true);
  const [optionalFeesOrigin, setOptionalFeesOrigin] = useState([]);
  const [optionalFeesDestination, setOptionalFeesDestination] = useState([]);

  useEffect(() => {
    if (services.length > 0) {
      const originServices = services.filter((service) =>
        service.id.startsWith("origin-")
      );
      const destinationServices = services.filter((service) =>
        service.id.startsWith("destination-")
      );
      setOptionalFeesOrigin(originServices);
      setOptionalFeesDestination(destinationServices);
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [services]);

  const renderServices = (servicesList) =>
    servicesList.map((service) => (
      <div key={service.id} className="optional-services-modal-service-item">
        <label>
          <input
            type="checkbox"
            checked={!!selectedFees[service.id]} // Transforma undefined em false
            onChange={() => toggleFee(service.id)} // Alterna o estado do serviço
          />
          {translate(service.name)} - {service.value} BRL
        </label>
      </div>
    ));

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={`${translate("optionalServicesFor")} ${title}`}
      className="optional-services-modal"
      overlayClassName="optional-services-modal-overlay"
      ariaHideApp={false}
    >
      <div className="optional-services-modal-header">
        <h4>{title}</h4>
        <button
          className="optional-services-modal-close-button"
          onClick={onRequestClose}
          aria-label={translate("closeModal")}
        >
          &times;
        </button>
      </div>
      <div className="optional-services-modal-content">
        {loading ? (
          <p className="optional-services-modal-loading">
            Carregando serviços opcionais...
          </p>
        ) : services.length === 0 ? (
          <p className="optional-services-modal-no-services">
            {translate("noOptionalServicesOrigin")}
          </p>
        ) : (
          <>
            <h5>{translate("optionalServicesOrigin")}</h5>
            {optionalFeesOrigin.length > 0 ? (
              renderServices(optionalFeesOrigin)
            ) : (
              <p>{translate("noOptionalServicesOrigin")}</p>
            )}
            <h5>{translate("optionalServicesDestination")}</h5>
            {optionalFeesDestination.length > 0 ? (
              renderServices(optionalFeesDestination)
            ) : (
              <p>{translate("noOptionalServicesDestination")}</p>
            )}
          </>
        )}
      </div>
      <div className="optional-services-modal-footer">
        <button
          className="optional-services-modal-button"
          onClick={onRequestClose}
        >
          {translate("cancel")}
        </button>
        <button className="optional-services-modal-button" onClick={onSave}>
          {translate("save")}
        </button>
      </div>
    </Modal>
  );
};

export default OptionalServicesModal;
