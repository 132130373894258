import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchLeads } from "../store/actions/leadActions";
import { useNavigate } from "react-router-dom";
import "../assets/styles/dashboard.css";
import { FaEllipsisV } from "react-icons/fa"; // Importando a biblioteca de ícones

const LeadColumns = ({ activeView }) => {
  const columns = useMemo(() => {
    switch (activeView) {
      case "comercial":
        return ["Número do Lead", "Nome", "CPF/CNPJ", "Status", "Limite de Crédito"];
      case "planejamento":
        return ["Data de Registro", "Cidade", "Estado", "Termos de Pagamento", "Risco de Crédito"];
      case "operacional":
        return ["Nome", "Rua", "Cidade", "Estado", "Telefone"];
      default:
        return [];
    }
  }, [activeView]);

  return (
    <>
      {columns.map((col, index) => (
        <div key={index}>{col}</div>
      ))}
    </>
  );
};

const LeadRows = ({ lead, activeView, onActionClick }) => {
  const rows = useMemo(() => {
    switch (activeView) {
      case "comercial":
        const totalGeneral = lead.financeSection?.creditLimit || 0;
        const totalLeadValue = totalGeneral.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });

        return [
          lead.logSection?.tenantLeadNumber || "",
          lead.registrationSection?.name || "",
          lead.registrationSection?.CPF_CNPJ || "",
          lead.logSection?.status || "",
          totalLeadValue,
        ];
      case "planejamento":
        return [
          new Date(lead.logSection?.registrationDate?.$date).toLocaleDateString() || "",
          lead.registrationSection?.address?.city || "",
          lead.registrationSection?.address?.state || "",
          lead.financeSection?.paymentTerms || "",
          lead.financeSection?.creditRisk || "",
        ];
      case "operacional":
        return [
          lead.registrationSection?.name || "",
          lead.registrationSection?.address?.street || "",
          lead.registrationSection?.address?.city || "",
          lead.registrationSection?.address?.state || "",
          lead.registrationSection?.phone || "",
        ];
      default:
        return [];
    }
  }, [lead, activeView]);

  return (
    <>
      {rows.map((row, index) => (
        <div className="quotes-list-item" key={index}>
          {row}
        </div>
      ))}
      <div className="quotes-list-item">
        <FaEllipsisV
          onClick={(event) => onActionClick(lead, event)}
          className="action-icon"
        />
      </div>
    </>
  );
};

const ActionMenu = ({ lead, navigate, onClose }) => {
  const handleEdit = useCallback(() => {
    // Implementar a lógica de edição do lead
    onClose();
  }, [lead._id, navigate, onClose]);

  const handlePDF = useCallback(() => {
    // Implementar a lógica de geração de PDF do lead
    onClose();
  }, [lead._id, onClose]);

  const handleCopy = useCallback(() => {
    // Implementar a lógica de cópia do lead
    onClose();
  }, [lead._id, onClose]);

  const handleEmail = useCallback(() => {
    // Implementar a lógica de envio por email do lead
    onClose();
  }, [lead._id, onClose]);

  const handleSale = useCallback(() => {
    // Implementar a lógica de geração de venda do lead
    onClose();
  }, [lead._id, onClose]);

  return (
    <div className="action-menu-container">
      <ul className="action-menu-list">
        <li onClick={handleEdit}>Editar</li>
        <li onClick={handlePDF}>Gerar PDF</li>
        <li onClick={handleCopy}>Copiar</li>
        <li onClick={handleEmail}>Enviar por Email</li>
        <li onClick={handleSale}>Gerar Venda</li>
      </ul>
    </div>
  );
};

const LeadsBoard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { leads, loading, error } = useSelector((state) => state.leads);
  const [activeView, setActiveView] = useState("comercial");
  const [selectedLead, setSelectedLead] = useState(null);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    dispatch(fetchLeads());
  }, [dispatch]);

  const handleViewChange = useCallback((view) => {
    setActiveView(view);
  }, []);

  const handleActionClick = useCallback((lead, event) => {
    if (event && event.target) {
      const rect = event.target.getBoundingClientRect();
      setMenuPosition({ top: rect.bottom, left: rect.left });
      setSelectedLead(lead);
    }
  }, []);

  const handleCloseMenu = useCallback(() => {
    setSelectedLead(null);
  }, []);

  const handleCreateLead = useCallback(() => {
    navigate("/lead");
  }, [navigate]);

  // Ordenar os leads em ordem decrescente pelo número do lead
  const sortedLeads = useMemo(() => {
    return leads.slice().sort((a, b) => {
      const numA = a.logSection?.tenantLeadNumber || 0;
      const numB = b.logSection?.tenantLeadNumber || 0;
      return numB - numA;
    });
  }, [leads]);

  if (loading) return <p>Carregando...</p>;
  if (error) return <p>Erro ao carregar leads: {error.message}</p>;

  return (
    <div className="dashboard-container">
      <h1>Painel de Leads</h1>
      <div className="view-buttons">
        <button onClick={() => handleViewChange("comercial")}>
          Visão Comercial
        </button>
        <button onClick={() => handleViewChange("planejamento")}>
          Visão Planejamento
        </button>
        <button onClick={() => handleViewChange("operacional")}>
          Visão Operacional
        </button>
        <button onClick={handleCreateLead} className="create-quote-button">
          Criar Lead
        </button>
      </div>

      <div className="quotes-list header">
        <LeadColumns activeView={activeView} />
      </div>

      {sortedLeads.map((lead) => (
        <div className="quotes-list" key={lead._id}>
          <LeadRows
            lead={lead}
            activeView={activeView}
            onActionClick={handleActionClick}
          />
        </div>
      ))}

      {selectedLead && (
        <div
          className="action-menu-container"
          style={{ top: menuPosition.top, left: menuPosition.left }}
        >
          <ActionMenu
            lead={selectedLead}
            navigate={navigate}
            onClose={handleCloseMenu}
          />
        </div>
      )}
    </div>
  );
};

export default LeadsBoard;