import {
  FETCH_QUOTES_SUCCESS,
  FETCH_QUOTES_FAILURE,
  //FETCH_QUOTE_BY_ID_SUCCESS,
  //FETCH_QUOTE_BY_ID_FAILURE,
  //CREATE_QUOTE_SUCCESS,
  //CREATE_QUOTE_FAILURE,
  //UPDATE_QUOTE_SUCCESS,
  //UPDATE_QUOTE_FAILURE,
} from '../actions/types';

const initialState = {
  quotes: [],
  quote: null,
  loading: true,
  error: null,
};

const quoteReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_QUOTES_SUCCESS:
      console.log('Quotes in Reducer:', action.payload); // Log das cotações no reducer
      return {
        ...state,
        quotes: action.payload,
        loading: false,
        error: null,
      };
    case FETCH_QUOTES_FAILURE:
      return {
        ...state,
        quotes: [],
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};


export default quoteReducer;
