import axios from '../utils/axiosConfig';

const API_URL = process.env.REACT_APP_API_URL;

const getAuthHeaders = () => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
};

// Funções de Autenticação
export const loginUser = async (credentials) => {
  try {
    const response = await axios.post(`${API_URL}/users/login`, credentials);
    
    const { token, tenantId, tenantInfo, user } = response.data;

    localStorage.setItem('token', token);
    localStorage.setItem('tenantId', tenantId);
    localStorage.setItem('tenantInfo', JSON.stringify(tenantInfo)); // Armazenando as informações do tenant

    return user;
  } catch (error) {
    console.error('Erro ao fazer login:', error);
    throw error;
  }
};

export const registerUser = async (userData) => {
  const response = await axios.post(`${API_URL}/users/register`, userData);
  return response.data;
};

// Funções para os Usuários
export const fetchAllUsers = async () => {
  const response = await axios.get(`${API_URL}/users`, getAuthHeaders());
  return response.data;
};

export const createUser = async (userData) => {
  const response = await axios.post(`${API_URL}/users`, userData, getAuthHeaders());
  return response.data;
};

export const deleteUser = async (id) => {
  const response = await axios.delete(`${API_URL}/users/${id}`, getAuthHeaders());
  return response.data;
};

// Funções para os Colaboradores
export const fetchCollaborators = async () => {
  const response = await axios.get(`${API_URL}/collaborators`, getAuthHeaders());
  return response.data;
};

export const createCollaborator = async (collaboratorData) => {
  const response = await axios.post(`${API_URL}/collaborators`, collaboratorData, getAuthHeaders());
  return response.data;
};

// Funções de House Bills
export const fetchHouseBills = async () => {
  const response = await axios.get(`${API_URL}/housebills`, getAuthHeaders());
  return response.data;
};

export const fetchHouseBillById = async (id) => {
  const response = await axios.get(`${API_URL}/housebills/${id}`, getAuthHeaders());
  return response.data;
};

export const createHouseBill = async (houseBillData) => {
  const response = await axios.post(`${API_URL}/housebills`, houseBillData, getAuthHeaders());
  return response.data;
};

export const updateHouseBill = async (id, houseBillData) => {
  const response = await axios.put(`${API_URL}/housebills/${id}`, houseBillData, getAuthHeaders());
  return response.data;
};

export const deleteHouseBill = async (id) => {
  const response = await axios.delete(`${API_URL}/housebills/${id}`, getAuthHeaders());
  return response.data;
};

// Funções de Master Bills
export const fetchMasterBills = async () => {
  const response = await axios.get(`${API_URL}/masterbills`, getAuthHeaders());
  return response.data;
};

export const fetchMasterBillById = async (id) => {
  const response = await axios.get(`${API_URL}/masterbills/${id}`, getAuthHeaders());
  return response.data;
};

export const createMasterBill = async (masterBillData) => {
  const response = await axios.post(`${API_URL}/masterbills`, masterBillData, getAuthHeaders());
  return response.data;
};

export const updateMasterBill = async (id, masterBillData) => {
  const response = await axios.put(`${API_URL}/masterbills/${id}`, masterBillData, getAuthHeaders());
  return response.data;
};

export const deleteMasterBill = async (id) => {
  const response = await axios.delete(`${API_URL}/masterbills/${id}`, getAuthHeaders());
  return response.data;
};

// Funções de Manifests
export const fetchManifests = async () => {
  const response = await axios.get(`${API_URL}/manifests`, getAuthHeaders());
  return response.data;
};

export const fetchManifestById = async (id) => {
  const response = await axios.get(`${API_URL}/manifests/${id}`, getAuthHeaders());
  return response.data;
};

export const createManifest = async (manifestData) => {
  const response = await axios.post(`${API_URL}/manifests`, manifestData, getAuthHeaders());
  return response.data;
};

export const updateManifest = async (id, manifestData) => {
  const response = await axios.put(`${API_URL}/manifests/${id}`, manifestData, getAuthHeaders());
  return response.data;
};

export const deleteManifest = async (id) => {
  const response = await axios.delete(`${API_URL}/manifests/${id}`, getAuthHeaders());
  return response.data;
};

// Funções de Invoicing
export const fetchInvoices = async () => {
  const response = await axios.get(`${API_URL}/invoices`, getAuthHeaders());
  return response.data;
};

export const fetchInvoiceById = async (id) => {
  const response = await axios.get(`${API_URL}/invoices/${id}`, getAuthHeaders());
  return response.data;
};

export const createInvoice = async (invoiceData) => {
  const response = await axios.post(`${API_URL}/invoices`, invoiceData, getAuthHeaders());
  return response.data;
};

export const updateInvoice = async (id, invoiceData) => {
  const response = await axios.put(`${API_URL}/invoices/${id}`, invoiceData, getAuthHeaders());
  return response.data;
};

export const deleteInvoice = async (id) => {
  const response = await axios.delete(`${API_URL}/invoices/${id}`, getAuthHeaders());
  return response.data;
};

// Funções de Accounts to Pay
export const fetchAccountsToPay = async () => {
  const response = await axios.get(`${API_URL}/accounts-to-pay`, getAuthHeaders());
  return response.data;
};

export const fetchAccountToPayById = async (id) => {
  const response = await axios.get(`${API_URL}/accounts-to-pay/${id}`, getAuthHeaders());
  return response.data;
};

export const createAccountToPay = async (accountToPayData) => {
  const response = await axios.post(`${API_URL}/accounts-to-pay`, accountToPayData, getAuthHeaders());
  return response.data;
};

export const updateAccountToPay = async (id, accountToPayData) => {
  const response = await axios.put(`${API_URL}/accounts-to-pay/${id}`, accountToPayData, getAuthHeaders());
  return response.data;
};

export const deleteAccountToPay = async (id) => {
  const response = await axios.delete(`${API_URL}/accounts-to-pay/${id}`, getAuthHeaders());
  return response.data;
};

// Funções de Veículos
export const fetchVehicles = async () => {
  const response = await axios.get(`${API_URL}/vehicles`, getAuthHeaders());
  return response.data;
};

export const fetchVehicleById = async (id) => {
  const response = await axios.get(`${API_URL}/vehicles/${id}`, getAuthHeaders());
  return response.data;
};

export const createVehicle = async (vehicleData) => {
  const response = await axios.post(`${API_URL}/vehicles`, vehicleData, getAuthHeaders());
  return response.data;
};

export const updateVehicle = async (id, vehicleData) => {
  const response = await axios.put(`${API_URL}/vehicles/${id}`, vehicleData, getAuthHeaders());
  return response.data;
};

export const deleteVehicle = async (id) => {
  const response = await axios.delete(`${API_URL}/vehicles/${id}`, getAuthHeaders());
  return response.data;
};

// Funções para Cotações
export const fetchQuotes = async () => {
  const response = await axios.get(`${API_URL}/quotes`, getAuthHeaders());
  console.log('Quotes API Response:', response.data); // Log do resultado
  return response.data;
};

export const createQuote = async (quoteData) => {
  try {
    const response = await axios.post(`${API_URL}/quotes`, quoteData, getAuthHeaders());
    return response.data;
  } catch (error) {
    console.error('Erro ao criar cotação:', error);
    throw error;
  }
};

export const updateQuote = async (id, quoteData) => {
  const response = await axios.put(`${API_URL}/quotes/${id}`, quoteData, getAuthHeaders());
  return response.data;
};

// Função fetchTrips (renomeada)
export const fetchTrips = fetchManifests;

export const fetchQuoteById = async (id) => {
  const response = await axios.get(`${API_URL}/quotes/${id}`, getAuthHeaders());
  return response.data;
};

export const fetchTenantById = async (id) => {
  const response = await axios.get(`${API_URL}/tenants/${id}`, getAuthHeaders());
  return response.data;
};

// Função para buscar as informações do tenant pelo ID
export const fetchTenantInfoById = async (tenantId) => {
  try {
    const response = await axios.get(`${API_URL}/tenantinfo/${tenantId}`, getAuthHeaders());
    return response.data;
  } catch (error) {
    console.error('Erro ao buscar informações do tenant:', error);
    throw error;
  }
};

// Funções de Leads
export const fetchLeads = async () => {
  try {
    const response = await axios.get(`${API_URL}/leads`, getAuthHeaders());
    return response.data;
  } catch (error) {
    console.error('Erro ao buscar leads:', error);
    throw error;
  }
};

export const createLead = async (leadData) => {
  try {
    const response = await axios.post(`${API_URL}/leads`, leadData, getAuthHeaders());
    return response.data;
  } catch (error) {
    console.error('Erro ao criar lead:', error);
    throw error;
  }
};

export const updateLead = async (id, leadData) => {
  try {
    const response = await axios.put(`${API_URL}/leads/${id}`, leadData, getAuthHeaders());
    return response.data;
  } catch (error) {
    console.error(`Erro ao atualizar lead com ID ${id}:`, error);
    throw error;
  }
};

export const deleteLead = async (id) => {
  try {
    const response = await axios.delete(`${API_URL}/leads/${id}`, getAuthHeaders());
    return response.data;
  } catch (error) {
    console.error(`Erro ao deletar lead com ID ${id}:`, error);
    throw error;
  }
};

export const fetchLeadById = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/leads/${id}`, getAuthHeaders());
    return response.data;
  } catch (error) {
    console.error(`Erro ao buscar lead por ID ${id}:`, error);
    throw error;
  }
};

// Funções de Clientes
export const fetchClients = async () => {
  const response = await axios.get("/clients");
  console.log('Clients API Response:', response.data); // Log do resultado
  return response.data;
};

export const fetchClientById = async (id) => {
  const response = await axios.get(`/clients/${id}`);
  console.log(`Client API Response for ID ${id}:`, response.data); // Log do resultado
  return response.data;
};

export const createClient = async (clientData) => {
  try {
    const response = await axios.post("/clients", clientData);
    return response.data;
  } catch (error) {
    console.error('Erro ao criar cliente:', error);
    throw error;
  }
};

export const updateClient = async (id, clientData) => {
  const response = await axios.put(`/clients/${id}`, clientData);
  return response.data;
};

export const deleteClient = async (id) => {
  const response = await axios.delete(`/clients/${id}`);
  return response.data;
};

