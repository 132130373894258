//frontend\src\components\quoteRender.js
// Este arquivo é responsável por renderizar os cálculos de quoteResults.jsx em HTML.
// Ele é chamado pelo QuoteResults.jsx que realiza os cálculos e formatações.
// Ele é um arquivo de apoio para o QuoteResults.jsx.

import React from "react";
import { translate } from "../Dictionary";

// Função para renderizar a seção de serviços
export const renderServiceSection = (
  options,
  selectedOption,
  handleOptionClick,
  formatCurrency
) => {
  // Garantir que options seja sempre um array
  if (!Array.isArray(options)) return null;

  return (
    <div className="service-section">
      <h3>Seção de Serviços</h3>
      <table className="table-auto">
        <thead>
          <tr>
            <th>Operador (Veículo)</th>
            <th>Tipo de Entrega</th>
            <th>Prazo</th>
            <th>Custo Total</th>
            <th>Selecionar</th>
          </tr>
        </thead>
        <tbody>
          {options.map((option) => (
            <tr
              key={option.index}
              className={
                selectedOption?.index === option.index ? "selected-row" : ""
              }
            >
              <td>{option.operator}</td>
              <td>{option.label}</td>
              <td>{option.eta}</td>
              <td>{formatCurrency(option.totalCost)}</td>
              <td>
                <button
                  className={`quote-page__button ${
                    selectedOption?.index === option.index ? "selected" : ""
                  }`}
                  onClick={() => handleOptionClick(option)}
                >
                  Selecionar
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

// Função para renderizar o serviço selecionado
export const renderSelectedService = (selectedOption, formatCurrency) => {
  if (!selectedOption) return null;
  return (
    <div className="selected-service">
      <h3>Serviço Selecionado</h3>
      <table className="table-auto">
        <thead>
          <tr>
            <th>Tipo de Serviço</th>
            <th>Prazo</th>
            <th>Custo Total</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="service-label">{selectedOption.label}</td>
            <td className="service-eta">{selectedOption.eta}</td>
            <td className="service-cost">
              {formatCurrency(selectedOption.totalCost)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

// Função para renderizar o resumo de taxas
export const renderFeeSummary = (
  serviceType,
  vehicleType,
  feeSummary,
  formatCurrency
) => {
  if (!feeSummary) return null;
  return (
    <div className="fee-summary">
      <h3>Resumo de Taxas</h3>
      <table className="table-auto">
        <thead>
          <tr>
            <th>Ad Valorem</th>
            <th>Gerenciamento de Risco</th>
            <th>Suframa</th>
            <th>Pedágio</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{formatCurrency(feeSummary.adValorem)}</td>
            <td>{formatCurrency(feeSummary.riskManagement)}</td>
            <td>{formatCurrency(feeSummary.suframa)}</td>
            <td>
              {formatCurrency(
                getTollValue(serviceType, vehicleType, feeSummary)
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

// Função para renderizar a seção de serviços locais
export const renderLocalServiceSection = (
  localServiceSection,
  formatCurrency
) => {
  if (!localServiceSection) return null;
  return (
    <div className="local-service-section">
      <h3>Seção de Serviços Locais</h3>
      <table className="table-auto">
        <thead>
          <tr>
            <th>Custo de Coleta</th>
            <th>Custo de Entrega</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{formatCurrency(localServiceSection.pickupCost)}</td>
            <td>{formatCurrency(localServiceSection.deliveryCost)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

// Função para renderizar o total e impostos
export const renderTotalAndTaxes = (
  totalServices,
  icms,
  totalGeneral,
  formatCurrency
) => {
  return (
    <div className="total-and-taxes">
      <h3>Total e Impostos</h3>
      <table className="table-auto">
        <thead>
          <tr>
            <th>Total de Serviços</th>
            <th>ICMS</th>
            <th>Total Geral</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{formatCurrency(totalServices)}</td>
            <td>{formatCurrency(icms)}</td>
            <td>{formatCurrency(totalGeneral)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

// Função para obter o valor do pedágio
export const getTollValue = (serviceType, vehicleType, feeSummary) => {
  if (!feeSummary || !feeSummary.toll) return 0;

  if (serviceType === "Frete Fracionado") {
    return feeSummary.toll.LCL_toll || 0;
  } else if (serviceType === "Frete Dedicado") {
    const vehicleKey = Object.keys(feeSummary.toll).find(
      (key) => translate(key) === vehicleType
    );
    return feeSummary.toll[vehicleKey] || 0;
  }
  return 0;
};

// Função para renderizar os serviços opcionais
export const renderOptionalServices = (
  selectedOriginServices,
  selectedDestinationServices,
  totalOptionalFees,
  formatCurrency
) => {
  const renderServiceList = (services) => {
    if (!Array.isArray(services))
      return translate("Nenhum serviço opcional selecionado");
    return (
      <ul>
        {services.map((service, index) => (
          <li key={index}>
            {translate(service.name)} - {formatCurrency(service.value)}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="optional-services">
      <h3>{translate("Serviços Opcionais")}</h3>
      <table className="table-auto">
        <thead>
          <tr>
            <th>{translate("Serviços na Origem")}</th>
            <th>{translate("Serviços no Destino")}</th>
            <th>{translate("Total de Taxas Opcionais")}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{renderServiceList(selectedOriginServices)}</td>
            <td>{renderServiceList(selectedDestinationServices)}</td>
            <td>{formatCurrency(totalOptionalFees)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};