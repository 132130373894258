import {
  FETCH_CLIENTS_SUCCESS,
  FETCH_CLIENTS_FAILURE,
  CREATE_CLIENT_REQUEST,
  CREATE_CLIENT_SUCCESS,
  CREATE_CLIENT_FAILURE,
} from '../actions/types';

const initialState = {
  clients: [],
  loading: true,
  error: null,
};

const clientReducer = (state = initialState, action) => {
  console.log('Action received:', action); // Log the action received
  switch (action.type) {
    case FETCH_CLIENTS_SUCCESS:
      console.log('FETCH_CLIENTS_SUCCESS:', action.payload); // Log the payload
      return {
        ...state,
        clients: action.payload,
        loading: false,
        error: null,
      };
    case FETCH_CLIENTS_FAILURE:
      console.log('FETCH_CLIENTS_FAILURE:', action.payload); // Log the error
      return {
        ...state,
        clients: [],
        loading: false,
        error: action.payload,
      };
    case CREATE_CLIENT_REQUEST:
      console.log('CREATE_CLIENT_REQUEST'); // Log the request action
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CREATE_CLIENT_SUCCESS:
      console.log('CREATE_CLIENT_SUCCESS:', action.payload); // Log the payload
      return {
        ...state,
        clients: [...state.clients, action.payload],
        loading: false,
        error: null,
      };
    case CREATE_CLIENT_FAILURE:
      console.log('CREATE_CLIENT_FAILURE:', action.payload); // Log the error
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default clientReducer;