import { combineReducers } from 'redux';
import authReducer from './authReducer';
import clientReducer from './clientReducer';
import quoteReducer from './quoteReducer';
import masterBillReducer from './masterBillReducer';
import leadReducer from './leadReducer'; // Importando o leadReducer
import taskReducer from './taskReducer';
// Adicione outros reducers aqui

const rootReducer = combineReducers({
  auth: authReducer,
  clients: clientReducer,
  quotes: quoteReducer,
  masterBills: masterBillReducer,
  leads: leadReducer, // Adicionando o leadReducer
  tasks: taskReducer,
  // Adicione outros reducers aqui
});

export default rootReducer;