// src/pages/ManifestPage.js
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTrips } from '../store/actions/manifestActions';
import '../assets/styles/index.css'; // Importando o CSS


const ManifestPage = () => {
  const dispatch = useDispatch();
  const { trips, loading, error } = useSelector((state) => state.trips);

  useEffect(() => {
    dispatch(fetchTrips());
  }, [dispatch]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="container">
      <h1 className="page-title">Manifestos</h1>
      <ul className="clients-list">
        {trips.map((trip) => (
          <li key={trip._id}>{trip.veiculo}</li>
        ))}
      </ul>
    </div>
  );
};

export default ManifestPage;
