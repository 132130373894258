// src/store/reducers/taskReducer.js
import {
    FETCH_TASKS_REQUEST,
    FETCH_TASKS_SUCCESS,
    FETCH_TASKS_FAILURE,
    FETCH_TASK_BY_ID_REQUEST,
    FETCH_TASK_BY_ID_SUCCESS,
    FETCH_TASK_BY_ID_FAILURE,
    CREATE_TASK_REQUEST,
    CREATE_TASK_SUCCESS,
    CREATE_TASK_FAILURE,
    UPDATE_TASK_REQUEST,
    UPDATE_TASK_SUCCESS,
    UPDATE_TASK_FAILURE,
    DELETE_TASK_REQUEST,
    DELETE_TASK_SUCCESS,
    DELETE_TASK_FAILURE,
  } from '../actions/types';
  
  const initialState = {
    tasks: [],
    loading: false,
    error: null,
    task: null,
  };
  
  const taskReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_TASKS_REQUEST:
      case FETCH_TASK_BY_ID_REQUEST:
      case CREATE_TASK_REQUEST:
      case UPDATE_TASK_REQUEST:
      case DELETE_TASK_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_TASKS_SUCCESS:
        return {
          ...state,
          loading: false,
          tasks: action.payload,
        };
      case FETCH_TASK_BY_ID_SUCCESS:
        return {
          ...state,
          loading: false,
          task: action.payload,
        };
      case CREATE_TASK_SUCCESS:
        return {
          ...state,
          loading: false,
          tasks: [...state.tasks, action.payload],
        };
      case UPDATE_TASK_SUCCESS:
        return {
          ...state,
          loading: false,
          tasks: state.tasks.map(task =>
            task._id === action.payload._id ? action.payload : task
          ),
        };
      case DELETE_TASK_SUCCESS:
        return {
          ...state,
          loading: false,
          tasks: state.tasks.filter(task => task._id !== action.payload),
        };
      case FETCH_TASKS_FAILURE:
      case FETCH_TASK_BY_ID_FAILURE:
      case CREATE_TASK_FAILURE:
      case UPDATE_TASK_FAILURE:
      case DELETE_TASK_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default taskReducer;