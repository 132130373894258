// QuotePage.js
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import cities from "../json/cities.json";
import QuoteInputs from "../components/quoteComponents/quoteInputs";
import QuoteResults from "../components/quoteComponents/quoteResults";
import { handleGeneratePDF, handleCopyToClipboard, handleSendByEmail } from "../components/quoteComponents/quoteExports";
import { fetchQuoteById } from "../services/api";
import OptionalServicesModal from "../components/quoteComponents/OptionalServicesModal";
import "../assets/styles/quotePage.css";
import "../assets/styles/table.css";
import { handleCalculateOrUpdate, handleSaveAndClose, handleSaveOptionalServices, getOptionalServices } from "../components/quoteComponents/quoteHandlers";

const QuotePage = () => {
  // Estado para armazenar os dados do formulário
  const [formData, setFormData] = useState({
    selectedClient: "",
    selectedService: "",
    selectedPickupCity: "",
    selectedDeliveryCity: "",
    peso: "",
    valor: "",
    volumes: "",
    selectedMerchandise: "",
  });

  // Estados para armazenar as cidades filtradas, resumo, ID da cotação, opção selecionada, serviços opcionais, taxas opcionais totais, descrição opcional e visibilidade do modal
  const [filteredPickupCities, setFilteredPickupCities] = useState([]);
  const [filteredDeliveryCities, setFilteredDeliveryCities] = useState([]);
  const [summary, setSummary] = useState({
    salesSummary: null,
    purchasingSummary: null,
    routeSummary: null,
  });
  const [quoteId, setQuoteId] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [optionalServices, setOptionalServices] = useState({});
  const [totalOptionalFees, setTotalOptionalFees] = useState(0);
  const [optionalDescription, setOptionalDescription] = useState("Nenhuma taxa opcional selecionada");
  const [showOptionalServices, setShowOptionalServices] = useState(false);

  // Obtendo parâmetros da URL e informações do localStorage
  const { id } = useParams();
  const tenantId = localStorage.getItem("tenantId");
  const user = localStorage.getItem("username");
  const navigate = useNavigate();

  // Efeito para buscar a cotação pelo ID quando o componente é montado ou o ID muda
  useEffect(() => {
    if (id) {
      fetchQuoteById(id)
        .then((quote) => {
          console.log("API Response:", quote);
          setQuoteId(quote._id);
          setFormData(quote.inputSection);
          setSummary({
            salesSummary: quote.apiResponse.salesSummary,
            purchasingSummary: quote.apiResponse.purchasingSummary,
            routeSummary: quote.routeSection,
          });
          if (quote.selectionSection) {
            console.log("Selected Option from API:", quote.selectionSection.selectedOption);
            setSelectedOption(quote.selectionSection.selectedOption || null);
            setOptionalServices(quote.selectionSection.optionalServices || {});
            setTotalOptionalFees(quote.selectionSection.totalAndTaxes?.totalOptionalFees || 0);
            setOptionalDescription(quote.selectionSection.totalAndTaxes?.optionalDescription || "Nenhuma taxa opcional selecionada");
          }
        })
        .catch((error) => {
          console.error("Erro ao buscar cotação:", error);
          alert("Erro ao buscar cotação. Por favor, tente novamente.");
        });
    }
  }, [id]);

  // Função para lidar com o clique em uma opção
  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  // Função para deselecionar a opção selecionada
  const deselectSelectedOption = () => {
    setSelectedOption(null);
  };

  // Obtendo a lista de serviços opcionais
  const optionalServicesList = getOptionalServices(summary);

  // Renderização do componente QuotePage
  return (
    <div className="quote-page">
      <header className="quote-page__header">
        <h1 className="quote-page__title">Cotação de transporte</h1>
        <p className="quote-page__description">Insira os dados da sua cotação</p>
      </header>

      <div className="quote-page__content">
        <QuoteInputs
          formData={formData}
          setFormData={setFormData}
          filteredPickupCities={filteredPickupCities}
          filteredDeliveryCities={filteredDeliveryCities}
          cities={cities}
          setFilteredPickupCities={setFilteredPickupCities}
          setFilteredDeliveryCities={setFilteredDeliveryCities}
        />

        {summary.salesSummary && (
          <QuoteResults
            formData={formData}
            salesSummary={summary.salesSummary}
            handleOptionClick={handleOptionClick}
            selectedOption={selectedOption}
            totalOptionalFees={totalOptionalFees}
            optionalDescriptionOrigin={optionalDescription}
            optionalDescriptionDestination={optionalDescription}
            optionalServicesSelected={optionalServices}
            optionalServices={optionalServicesList}
            selectedFees={optionalServices}
            toggleFee={(serviceId) => {
              setOptionalServices((prev) => ({
                ...prev,
                [serviceId]: !prev[serviceId],
              }));
            }}
            deselectSelectedOption={deselectSelectedOption}
          />
        )}

        <div className="quote-page__actions">
          {!quoteId && (
            <button
              type="button"
              onClick={() =>
                handleCalculateOrUpdate(
                  formData,
                  summary,
                  selectedOption,
                  totalOptionalFees,
                  tenantId,
                  user,
                  setSummary,
                  setQuoteId,
                  optionalServices
                )
              }
              className="quote-page__button"
            >
              Calcular Frete
            </button>
          )}

          {quoteId && (
            <button
              type="button"
              onClick={() => {
                handleCalculateOrUpdate(
                  formData,
                  summary,
                  selectedOption,
                  totalOptionalFees,
                  tenantId,
                  user,
                  setSummary,
                  setQuoteId,
                  optionalServices
                );
                deselectSelectedOption();
              }}
              className="quote-page__button"
            >
              Recalcular Frete
            </button>
          )}

          {quoteId && (
            <button
              type="button"
              onClick={() => setShowOptionalServices(true)}
              className="quote-page__button"
            >
              Adicionar Serviços Opcionais
            </button>
          )}

          {quoteId && (
            <button
              type="button"
              onClick={() => handleGeneratePDF(quoteId)}
              className="quote-page__button"
              disabled={!selectedOption}
            >
              Gerar PDF
            </button>
          )}

          {quoteId && (
            <button
              type="button"
              onClick={() => handleCopyToClipboard(quoteId)}
              className="quote-page__button"
              disabled={!selectedOption}
            >
              Compartilhar
            </button>
          )}

          {quoteId && (
            <button
              type="button"
              onClick={() => handleSendByEmail(quoteId)}
              className="quote-page__button"
              disabled={!selectedOption}
            >
              Enviar por Email
            </button>
          )}

          {quoteId && (
            <button
              type="button"
              onClick={() =>
                handleSaveAndClose(
                  quoteId,
                  formData,
                  summary,
                  selectedOption,
                  totalOptionalFees,
                  tenantId,
                  user,
                  navigate,
                  optionalServices
                )
              }
              className="quote-page__button close-button"
            >
              Salvar e Fechar
            </button>
          )}
        </div>
      </div>

      <OptionalServicesModal
        isOpen={showOptionalServices}
        onRequestClose={() => setShowOptionalServices(false)}
        services={optionalServicesList}
        selectedFees={optionalServices}
        toggleFee={(serviceId) => {
          setOptionalServices((prev) => ({
            ...prev,
            [serviceId]: !prev[serviceId],
          }));
        }}
        title="Serviços Opcionais"
        onSave={() =>
          handleSaveOptionalServices(
            optionalServices,
            setShowOptionalServices,
            () => getOptionalServices(summary)
          )
        }
      />
    </div>
  );
};

export default QuotePage;