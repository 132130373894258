import { 
  fetchClients as fetchClientsAPI, 
  createClient as createClientAPI, 
  updateClient as updateClientAPI 
} from '../../services/api';
import {
  FETCH_CLIENTS_REQUEST,
  FETCH_CLIENTS_SUCCESS,
  FETCH_CLIENTS_FAILURE,
  CREATE_CLIENT_REQUEST,
  CREATE_CLIENT_SUCCESS,
  CREATE_CLIENT_FAILURE,
  UPDATE_CLIENT_REQUEST,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAILURE,
} from './types';

// Função auxiliar para lidar com erros
const handleError = (dispatch, actionType, error) => {
  console.error(`Erro ao executar ação ${actionType}:`, error);
  dispatch({ type: actionType, payload: error.message });
};

// Ação para buscar clientes
export const fetchClients = () => async (dispatch) => {
  dispatch({ type: FETCH_CLIENTS_REQUEST });
  try {
    const data = await fetchClientsAPI();
    if (process.env.NODE_ENV === 'development') {
      console.log('Fetched Clients Data:', data);
    }
    dispatch({ type: FETCH_CLIENTS_SUCCESS, payload: data });
  } catch (error) {
    handleError(dispatch, FETCH_CLIENTS_FAILURE, error);
  }
};

// Ação para criar um novo cliente
export const createClientAction = (client) => async (dispatch) => {
  dispatch({ type: CREATE_CLIENT_REQUEST });
  try {
    const data = await createClientAPI(client);
    dispatch({ type: CREATE_CLIENT_SUCCESS, payload: data });
  } catch (error) {
    handleError(dispatch, CREATE_CLIENT_FAILURE, error);
  }
};

// Ação para atualizar um cliente existente
export const updateClientAction = (id, client) => async (dispatch) => {
  dispatch({ type: UPDATE_CLIENT_REQUEST });
  try {
    const data = await updateClientAPI(id, client);
    dispatch({ type: UPDATE_CLIENT_SUCCESS, payload: data });
  } catch (error) {
    handleError(dispatch, UPDATE_CLIENT_FAILURE, error);
  }
};