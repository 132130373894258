// src/pages/MaintenancePage.js
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMaintenances, createMaintenanceAction, updateMaintenanceAction } from '../store/actions/maintenanceActions';
import MaintenanceModal from '../components/MaintenanceModal';

const MaintenancePage = () => {
  const dispatch = useDispatch();
  const { maintenances, loading, error } = useSelector((state) => state.maintenances);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMaintenance, setSelectedMaintenance] = useState(null);

  useEffect(() => {
    dispatch(fetchMaintenances());
  }, [dispatch]);

  const handleEditMaintenance = (maintenance) => {
    setSelectedMaintenance(maintenance);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedMaintenance(null);
  };

  const handleEditSubmit = (updatedMaintenance) => {
    dispatch(updateMaintenanceAction(updatedMaintenance._id, updatedMaintenance));
    closeModal();
  };

  const handleCreateSubmit = (newMaintenance) => {
    dispatch(createMaintenanceAction(newMaintenance));
    closeModal();
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className="container maintenance-page__container">
      <h1 className="maintenance-page__title">Manutenções</h1>
      <button
        className="maintenance-page__button"
        onClick={() => setIsModalOpen(true)}
      >
        Nova Manutenção
      </button>
      <ul className="list">
        {Array.isArray(maintenances) && maintenances.map((maintenance) => (
          <li key={maintenance._id} className="list__item flex items-center justify-between">
            <div className="flex flex-col sm:flex-row">
              <div className="mr-4">
                <strong>Veículo:</strong> {maintenance.veiculo}
              </div>
              <div className="mr-4">
                <strong>Data:</strong> {maintenance.data}
              </div>
              <div>
                <strong>Descrição:</strong> {maintenance.descricao}
              </div>
            </div>
            <button
              className="maintenance-page__button"
              onClick={() => handleEditMaintenance(maintenance)}
            >
              Editar
            </button>
          </li>
        ))}
      </ul>
      {isModalOpen && (
        <MaintenanceModal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          onSubmit={selectedMaintenance ? handleEditSubmit : handleCreateSubmit}
          maintenance={selectedMaintenance}
        />
      )}
    </div>
  );
};

export default MaintenancePage;
