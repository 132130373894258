import React, { useState, useEffect } from 'react';
import { createUser, fetchAllUsers } from '../services/api';

const UsersPage = () => {
  const [users, setUsers] = useState([]);
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    role: '',
    accessiblePages: []
  });

  useEffect(() => {
    fetchAllUsers().then(setUsers);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prev) => {
      if (checked) {
        return { ...prev, accessiblePages: [...prev.accessiblePages, name] };
      } else {
        return { ...prev, accessiblePages: prev.accessiblePages.filter(page => page !== name) };
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    createUser(formData).then(() => {
      fetchAllUsers().then(setUsers);
    });
  };

  return (
    <div className="container">
      <h1>Cadastro de Usuários</h1>
      <form onSubmit={handleSubmit}>
        <div className="form__group">
          <label className="form__label">Nome de Usuário</label>
          <input type="text" name="username" value={formData.username} onChange={handleChange} className="form__input" />
        </div>
        <div className="form__group">
          <label className="form__label">Email</label>
          <input type="email" name="email" value={formData.email} onChange={handleChange} className="form__input" />
        </div>
        <div className="form__group">
          <label className="form__label">Senha</label>
          <input type="password" name="password" value={formData.password} onChange={handleChange} className="form__input" />
        </div>
        <div className="form__group">
          <label className="form__label">Função</label>
          <select name="role" value={formData.role} onChange={handleChange} className="form__input">
            <option value="">Selecione uma função</option>
            <option value="admin">Admin</option>
            <option value="user">Usuário</option>
          </select>
        </div>
        <div className="form__group">
          <label className="form__label">Páginas Acessíveis</label>
          <div>
            <label>
              <input type="checkbox" name="housebill" onChange={handleCheckboxChange} />
              House Bill
            </label>
            <label>
              <input type="checkbox" name="masterbill" onChange={handleCheckboxChange} />
              Master Bill
            </label>
            <label>
              <input type="checkbox" name="manifest" onChange={handleCheckboxChange} />
              Manifest
            </label>
            <label>
              <input type="checkbox" name="maintenance" onChange={handleCheckboxChange} />
              Manutenção
            </label>
            <label>
              <input type="checkbox" name="invoicing" onChange={handleCheckboxChange} />
              Invoicing
            </label>
            <label>
              <input type="checkbox" name="accounts-to-pay" onChange={handleCheckboxChange} />
              Contas a Pagar
            </label>
            <label>
              <input type="checkbox" name="clients" onChange={handleCheckboxChange} />
              Clientes
            </label>
            <label>
              <input type="checkbox" name="contacts" onChange={handleCheckboxChange} />
              Contatos
            </label>
          </div>
        </div>
        <button type="submit" className="button">Cadastrar</button>
      </form>
      <h2>Lista de Usuários</h2>
      <ul className="list">
        {users.map((user) => (
          <li key={user._id} className="list__item">
            {user.username} - {user.role}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UsersPage;
