// src/store/reducers/masterBillReducer.js

const initialState = {
    masterBillList: [],
    loading: false,
    error: null
  };
  
  const masterBillReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'FETCH_MASTER_BILLS_REQUEST':
        return { ...state, loading: true };
      case 'FETCH_MASTER_BILLS_SUCCESS':
        return { ...state, loading: false, masterBillList: action.payload };
      case 'FETCH_MASTER_BILLS_FAILURE':
        return { ...state, loading: false, error: action.error };
      default:
        return state;
    }
  };
  
  export default masterBillReducer;
  