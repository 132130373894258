export const terms = `
Entre as partes acima, celebra-se o presente acordo para a prestação de serviços de transferência de cargas, com coleta e entrega nas cidades atendidas, conforme as seguintes cláusulas:

1. Responsabilidades do Cliente
Documentação: O cliente deve apresentar a nota fiscal corretamente preenchida e sem rasuras, contendo as mesmas informações do remetente e destinatário. Em caso de irregularidades, a carga será retida até regularização.
CT-e: Clientes obrigados a emitir CT-e devem apresentá-lo junto com a nota fiscal.
Limite de Valor: A Declaração de Conteúdo só é aceita para cargas até R$ 500,00.
ICMS: Caso seja desobrigado a recolher ICMS, deve anexar a carta de isenção.
Documentos Adicionais: Para locações, anexar o contrato assinado; em casos de alíquota ou substituição tributária, anexar guia de pagamento.

2. Volume Aceito por Embarque
Limites: Até 600 kg e dimensões máximas de 2,15m (altura, largura e comprimento). Cubagem de até 6 m³ (equivalente a 1000 kg). Cargas acima desses limites requerem consulta prévia.
Cargas Fracionadas: Aceitas até 2000 kg em determinadas filiais; acima disso, é necessário pré-alerta.
Cubagem: Segue a regra de 167 kg/m³, conforme o modal aéreo.

3. Coleta e Entrega
Peso: Cargas fracionadas até 60 kg por volume e dimensões totais (C x L x A) até 300 cm. Acima de 250 kg por embarque requer consulta.
Taxas Adicionais: Cobrança de taxas para dificuldades de acesso ou atrasos no agendamento.
Responsabilidade: O cliente deve consultar a prestadora sobre rastreamento e seguro.

4. Restrições para Embarque
Proibido transportar armas, munições, café, cargas radioativas, entre outros.

5. Embalagem e Cargas Especiais
Responsabilidade: O cliente é responsável por embalar adequadamente as cargas. Em caso de embalagem inadequada, a prestadora não se responsabiliza por danos.
Cargas Perecíveis: Devem ser acondicionadas em isopor com controle de temperatura.
Cargas Vulneráveis: Confecções e eletrônicos só são aceitos em embalagens lacradas (ex.: saco-meia ou ráfia).

6. Prazos
Consulta: Prazos de entrega devem ser consultados diretamente com a prestadora.

7. Retirada de Cargas
Identificação: Funcionários e representantes do cliente devem apresentar identificação adequada (uniforme e crachá) para a retirada de cargas. Em casos de ausência de identificação, é necessária uma autorização por e-mail.

8. Ressarcimentos e Seguros
Cobertura: Ressarcimentos seguem as apólices de seguro contratadas. Alguns itens, como dinheiro e joias, não são cobertos.
Prazos: Indenizações por avarias ou extravios serão efetuadas em até 30 dias após a comprovação.

9. Cobranças e Atrasos
Armazenagem: Cargas não retiradas em até um dia após a chegada serão consideradas em armazenagem, sujeitas a cobrança.
Atrasos no Pagamento: Resultam em bloqueio da conta e inclusão do cliente em serviços de proteção ao crédito. Multas de 5% e juros de 0,033% ao dia serão aplicados.

10. Foro
Jurídico: As partes elegem o Foro da Comarca de Jacareí - SP para dirimir quaisquer disputas relacionadas a este contrato.
`;
