import React from 'react';
import { Link } from 'react-router-dom';
import { translate } from '../components/Dictionary'; // Certifique-se de que o caminho está correto
import '../assets/styles/navbar.css'; // Certifique-se de que o caminho está correto

const Navbar = ({ handleLogout }) => (
  <nav className="navbar">
    <div className="navbar__left">
      <Link className="navbar__link" to="/">{translate('home')}</Link>

      <div className="dropdown">
        <button className="dropbtn">{translate('commercial')}</button>
        <div className="dropdown-content">
          <div className="dropdown">
            <Link className="navbar__link" to="#">{translate('quotes')}</Link>
            <div className="dropdown-content dropdown-submenu">
              <Link className="navbar__link" to="/quotes">{translate('quote_board')}</Link>
              <Link className="navbar__link" to="/quote">{translate('create_quote')}</Link>
            </div>
          </div>
          <div className="dropdown">
            <Link className="navbar__link" to="#">{translate('crm')}</Link>
            <div className="dropdown-content dropdown-submenu">
              <Link className="navbar__link" to="/leads">{translate('lead_board')}</Link>
              <Link className="navbar__link" to="/lead">{translate('create_lead')}</Link>
              <Link className="navbar__link" to="/tasks">{translate('task_board')}</Link>
              <Link className="navbar__link" to="/task">{translate('create_task')}</Link>
            </div>
          </div>
        </div>
      </div>

      <div className="dropdown">
        <button className="dropbtn">{translate('operations')}</button>
        <div className="dropdown-content">
          <Link className="navbar__link" to="/housebill">{translate('house_bill')}</Link>
          <Link className="navbar__link" to="/masterbill">{translate('master_bill')}</Link>
          <Link className="navbar__link" to="/manifest">{translate('manifest')}</Link>
          <Link className="navbar__link" to="/maintenance">{translate('maintenance')}</Link>
        </div>
      </div>

      <div className="dropdown">
        <button className="dropbtn">{translate('financial')}</button>
        <div className="dropdown-content">
          <Link className="navbar__link" to="/invoicing">{translate('invoicing')}</Link>
          <Link className="navbar__link" to="/accounts-to-pay">{translate('accounts_to_pay')}</Link>
        </div>
      </div>

      <div className="dropdown">
        <button className="dropbtn">{translate('registrations')}</button>
        <div className="dropdown-content">
          <Link className="navbar__link" to="/clients">{translate('clients')}</Link>
          <Link className="navbar__link" to="/contacts">{translate('contacts')}</Link>
          <Link className="navbar__link" to="/collaborators">{translate('collaborators')}</Link>
          <Link className="navbar__link" to="/users">{translate('users')}</Link>
        </div>
      </div>
    </div>

    <div className="navbar__right">
      <button className="logout-button" onClick={handleLogout}>{translate('logout')}</button>
    </div>
  </nav>
);

export default Navbar;