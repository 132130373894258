const initialState = {
  leads: [],
  loading: false,
  error: null,
};

const leadReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_LEADS_REQUEST':
    case 'CREATE_LEAD_REQUEST':
    case 'UPDATE_LEAD_REQUEST':
    case 'DELETE_LEAD_REQUEST':
      return {
        ...state,
        loading: true,
        error: null,
      };

    case 'FETCH_LEADS_SUCCESS':
      return {
        ...state,
        leads: action.payload,
        loading: false,
        error: null,
      };

    case 'CREATE_LEAD_SUCCESS':
      return {
        ...state,
        leads: [...state.leads, action.payload],
        loading: false,
        error: null,
      };

    case 'UPDATE_LEAD_SUCCESS':
      return {
        ...state,
        leads: state.leads.map((lead) =>
          lead._id === action.payload._id ? action.payload : lead
        ),
        loading: false,
        error: null,
      };

    case 'DELETE_LEAD_SUCCESS':
      return {
        ...state,
        leads: state.leads.filter((lead) => lead._id !== action.payload),
        loading: false,
        error: null,
      };

    case 'FETCH_LEADS_FAILURE':
    case 'CREATE_LEAD_FAILURE':
    case 'UPDATE_LEAD_FAILURE':
    case 'DELETE_LEAD_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default leadReducer;