// src/store/actions/taskActions.js
import axios from '../../utils/axiosConfig';
import {
  FETCH_TASKS_REQUEST,
  FETCH_TASKS_SUCCESS,
  FETCH_TASKS_FAILURE,
  FETCH_TASK_BY_ID_REQUEST,
  FETCH_TASK_BY_ID_SUCCESS,
  FETCH_TASK_BY_ID_FAILURE,
  CREATE_TASK_REQUEST,
  CREATE_TASK_SUCCESS,
  CREATE_TASK_FAILURE,
  UPDATE_TASK_REQUEST,
  UPDATE_TASK_SUCCESS,
  UPDATE_TASK_FAILURE,
  DELETE_TASK_REQUEST,
  DELETE_TASK_SUCCESS,
  DELETE_TASK_FAILURE,
} from './types';

export const fetchTasksAction = () => async (dispatch) => {
  dispatch({ type: FETCH_TASKS_REQUEST });
  try {
    const response = await axios.get('/tasks');
    dispatch({ type: FETCH_TASKS_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_TASKS_FAILURE, payload: error.message });
  }
};

export const fetchTaskByIdAction = (id) => async (dispatch) => {
  dispatch({ type: FETCH_TASK_BY_ID_REQUEST });
  try {
    const response = await axios.get(`/tasks/${id}`);
    dispatch({ type: FETCH_TASK_BY_ID_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_TASK_BY_ID_FAILURE, payload: error.message });
  }
};

export const createTaskAction = (taskData) => async (dispatch) => {
  dispatch({ type: CREATE_TASK_REQUEST });
  try {
    const response = await axios.post('/tasks', taskData);
    dispatch({ type: CREATE_TASK_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: CREATE_TASK_FAILURE, payload: error.message });
  }
};

export const updateTaskAction = (id, taskData) => async (dispatch) => {
  dispatch({ type: UPDATE_TASK_REQUEST });
  try {
    const response = await axios.put(`/tasks/${id}`, taskData);
    dispatch({ type: UPDATE_TASK_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: UPDATE_TASK_FAILURE, payload: error.message });
  }
};

export const deleteTaskAction = (id) => async (dispatch) => {
  dispatch({ type: DELETE_TASK_REQUEST });
  try {
    await axios.delete(`/tasks/${id}`);
    dispatch({ type: DELETE_TASK_SUCCESS, payload: id });
  } catch (error) {
    dispatch({ type: DELETE_TASK_FAILURE, payload: error.message });
  }
};