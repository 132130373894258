import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logoutUser } from '../store/actions/authActions';
import Navbar from '../components/Navbar';

const HomePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logoutUser());
    navigate('/');  // Redireciona para a landing page após o logout
  };

  return (
    <div className="home-page">
      <Navbar handleLogout={handleLogout} /> 

      <header className="header flex justify-between items-center px-8 py-4 bg-white shadow-md">
        <h1 className="text-3xl font-bold">Bem-vindo(a)!</h1>
      </header>

      <section className="dashboard-section py-8 px-8 bg-gray-100">
        <h2 className="text-2xl font-semibold mb-4">Painel Principal</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <div className="dashboard-item bg-white p-6 shadow-md rounded-lg">
            <h3 className="text-xl font-semibold mb-2">Cotações</h3>
            <p>Gerencie suas cotações e visualize as mais recentes.</p>
            <button onClick={() => navigate('/quotes')} className="text-blue-600 hover:text-blue-800 mt-4">
              Acessar Cotações
            </button>
          </div>
          <div className="dashboard-item bg-white p-6 shadow-md rounded-lg">
            <h3 className="text-xl font-semibold mb-2">CRM</h3>
            <p>Controle suas atividades comerciais e interações com clientes.</p>
            <button onClick={() => navigate('/crm')} className="text-blue-600 hover:text-blue-800 mt-4">
              Acessar CRM
            </button>
          </div>
          <div className="dashboard-item bg-white p-6 shadow-md rounded-lg">
            <h3 className="text-xl font-semibold mb-2">Manutenção</h3>
            <p>Gerencie a frota e as manutenções periódicas dos veículos com eficiência.</p>
            <button onClick={() => navigate('/maintenance')} className="text-blue-600 hover:text-blue-800 mt-4">
              Acessar Manutenção
            </button>
          </div>
        </div>
      </section>

      <section className="recent-activities-section py-8 px-8 bg-white">
        <h2 className="text-2xl font-semibold mb-4">Atividades Recentes</h2>
        <div className="activities-list">
          {/* Exemplo de atividade recente */}
          <div className="activity-item mb-4">
            <p className="text-gray-700">Cotação criada em 20/08/2024 para o cliente XYZ.</p>
            <button onClick={() => navigate('/quote')} className="text-blue-600 hover:text-blue-800">
              Ver Detalhes
            </button>
          </div>
          {/* Adicione outras atividades aqui */}
        </div>
      </section>

      <section className="shortcuts-section py-8 px-8 bg-gray-100">
        <h2 className="text-2xl font-semibold mb-4">Atalhos</h2>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          <button onClick={() => navigate('/housebill')} className="shortcut-button bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700">
            House Bill
          </button>
          <button onClick={() => navigate('/masterbill')} className="shortcut-button bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700">
            Master Bill
          </button>
          <button onClick={() => navigate('/invoicing')} className="shortcut-button bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700">
            Faturamento
          </button>
          <button onClick={() => navigate('/accounts-to-pay')} className="shortcut-button bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700">
            Contas a Pagar
          </button>
        </div>
      </section>
    </div>
  );
};

export default HomePage;
