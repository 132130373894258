// quoteHandlers.js
import { calculateTotalCost } from "../quoteComponents/quoteResults";
import { handleCalculateFreight, handleSaveInitialQuote, handleUpdateFreight } from "../quoteComponents/quoteApi";
//import { navigate } from "react-router-dom";

export const handleCalculateOrUpdate = async (
  formData,
  summary,
  selectedOption,
  totalOptionalFees,
  tenantId,
  user,
  setSummary,
  setQuoteId,
  optionalServices
) => {
  if (
    !formData.selectedClient ||
    !formData.selectedService ||
    !formData.selectedPickupCity ||
    !formData.selectedDeliveryCity ||
    !formData.peso ||
    !formData.valor ||
    !formData.volumes ||
    !formData.selectedMerchandise
  ) {
    alert("Por favor, preencha todos os campos obrigatórios.");
    return;
  }

  if (!tenantId || !user) {
    alert("Tenant ID ou usuário não estão definidos. Faça o login novamente.");
    return;
  }

  const { totalServices, icms, totalGeneral } = calculateTotalCost(
    formData,
    summary.salesSummary,
    selectedOption,
    totalOptionalFees
  );

  const quoteData = {
    ...formData,
    tenantId,
    user,
    selectionSection: {
      selectedOption,
      optionalServices,
      totalAndTaxes: {
        totalServices,
        icms,
        totalGeneral,
      },
    },
  };

  try {
    const result = await handleCalculateFreight(quoteData);

    setSummary({
      salesSummary: result.objectSummaries.salesSummary,
      purchasingSummary: result.objectSummaries.purchasingSummary,
      routeSummary: result.objectSummaries.routeSummary,
    });

    const savedQuote = await handleSaveInitialQuote({
      tenantId,
      user,
      formData: quoteData,
      salesSummary: result.objectSummaries.salesSummary,
      purchasingSummary: result.objectSummaries.purchasingSummary,
      routeSummary: result.objectSummaries.routeSummary,
    });
    setQuoteId(savedQuote._id);
  } catch (error) {
    console.error("Erro ao calcular frete:", error);
    alert("Erro ao calcular frete. Por favor, tente novamente.");
  }
};

export const handleRecalculateFreight = async (
  quoteId,
  formData,
  summary,
  selectedOption,
  totalOptionalFees,
  tenantId,
  user,
  setSummary,
  setSelectedOption,
  optionalServices
) => {
  if (
    !formData.selectedClient ||
    !formData.selectedService ||
    !formData.selectedPickupCity ||
    !formData.selectedDeliveryCity ||
    !formData.peso ||
    !formData.valor ||
    !formData.volumes ||
    !formData.selectedMerchandise
  ) {
    alert("Por favor, preencha todos os campos obrigatórios.");
    return;
  }

  if (!tenantId || !user) {
    alert("Tenant ID ou usuário não estão definidos. Faça o login novamente.");
    return;
  }

  const { totalServices, icms, totalGeneral } = calculateTotalCost(
    formData,
    summary.salesSummary,
    selectedOption,
    totalOptionalFees
  );

  const quoteData = {
    ...formData,
    tenantId,
    user,
    selectionSection: {
      selectedOption,
      optionalServices,
      totalAndTaxes: {
        totalServices,
        icms,
        totalGeneral,
      },
    },
  };

  try {
    const result = await handleUpdateFreight(quoteId, quoteData);

    if (result && result.apiResponse && result.apiResponse.salesSummary) {
      setSummary({
        salesSummary: result.apiResponse.salesSummary || {},
        purchasingSummary: result.apiResponse.purchasingSummary || {},
        routeSummary: result.routeSection || {},
      });
      setSelectedOption(null); // Reset selected option
    } else {
      throw new Error("Resposta da API inválida.");
    }
  } catch (error) {
    console.error("Erro ao recalcular frete:", error);
    alert("Erro ao recalcular frete. Por favor, tente novamente.");
  }
};

export const handleSaveAndClose = async (
  quoteId,
  formData,
  summary,
  selectedOption,
  totalOptionalFees,
  tenantId,
  user,
  navigate,
  optionalServices
) => {
  try {
    if (!quoteId) {
      alert("Nenhuma cotação disponível para salvar.");
      return;
    }

    console.log("Calculando custos totais...");

    // Chama handleSaveOptionalServices para obter o total dos serviços opcionais selecionados
    const { selectedServiceTotal } = handleSaveOptionalServices(
      optionalServices,
      () => {}, // Passa uma função vazia para setShowOptionalServices, pois não queremos fechar o modal aqui
      () => getOptionalServices(summary)
    );

    // Atualiza totalOptionalFees com o valor retornado
    totalOptionalFees = selectedServiceTotal;

    const { totalServices, icms, totalGeneral } = calculateTotalCost(
      formData,
      summary.salesSummary,
      selectedOption,
      totalOptionalFees
    );

    const selectionSection = {
      selectedOption,
      optionalServices,
      totalAndTaxes: {
        totalServices,
        icms,
        totalGeneral,
      },
    };

    console.log("Dados da seção de seleção preparados:", selectionSection);

    await handleUpdateFreight(quoteId, { selectionSection });
    console.log("Cotação atualizada com sucesso.");
    navigate("/quotes"); // Redirecionar para a página de cotações
  } catch (error) {
    console.error("Erro ao salvar e fechar:", error);
    alert("Erro ao salvar e fechar a cotação.");
  }
};

export const handleSaveOptionalServices = (
  optionalServices,
  setShowOptionalServices,
  getOptionalServices
) => {
  console.log("Salvando serviços opcionais...");

  // Obtém a lista de serviços opcionais selecionados
  const selectedServices = getOptionalServices().filter(
    (service) => optionalServices[service.id]
  );

  // Extrai os nomes dos serviços opcionais selecionados
  const selectedServiceNames = selectedServices.map((service) => service.name);
  console.log("Serviços opcionais selecionados:", selectedServiceNames);

  // Calcula o total dos serviços opcionais selecionados
  const selectedServiceTotal = selectedServices.reduce(
    (total, service) => total + service.value,
    0
  );
  console.log("Total dos serviços opcionais selecionados:", selectedServiceTotal);

  // Fechar o modal ou realizar outra ação de fechamento
  setShowOptionalServices(false);

  // Retorna os valores calculados para uso posterior, se necessário
  return {
    selectedServiceNames,
    selectedServiceTotal,
  };
};

export const getOptionalServices = (summary) => {
  const { optionalServices } = summary.salesSummary || {};
  if (!optionalServices) return [];

  const originServices = optionalServices.optionalFeesOrigin?.[0]?.services.map(service => ({
    ...service,
    id: `origin-${service.id}`
  })) || [];

  const destinationServices = optionalServices.optionalFeesDestination?.[0]?.services.map(service => ({
    ...service,
    id: `destination-${service.id}`
  })) || [];

  return [...originServices, ...destinationServices];
};