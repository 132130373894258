import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Navbar from './Navbar';
import { logoutUser } from '../store/actions/authActions';

const Layout = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logoutUser());
    navigate('/login');
  };

  return (
    <>
      <Navbar handleLogout={handleLogout} />
      {children}
    </>
  );
};

export default Layout;
