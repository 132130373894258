import React, { useState, useEffect } from 'react';
import { createCollaborator, fetchCollaborators } from '../services/api';

const CollaboratorsPage = () => {
  const [collaborators, setCollaborators] = useState([]);
  const [formData, setFormData] = useState({
    nome: '',
    rg: '',
    cpf: '',
    dataNascimento: '',
    estadoCivil: '',
    profissao: '',
    sexo: '',
    naturalidade: '',
    nomePai: '',
    cpfPai: '',
    nomeMae: '',
    cpfMae: '',
    cep: '',
    uf: '',
    cidade: '',
    bairro: '',
    endereco: '',
    enderecoNro: '',
    complemento: '',
    fone: '',
    fax: '',
    celular: '',
    operadora: '',
    email: '',
    site: '',
    skype: '',
    proxVisita: '',
    limiteCredito: '',
    condicao: '',
    idCategoria: '',
    obs: ''
  });

  useEffect(() => {
    fetchCollaborators().then(setCollaborators);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    createCollaborator(formData).then(() => {
      fetchCollaborators().then(setCollaborators);
    });
  };

  return (
    <div className="container">
      <h1>Cadastro de Colaboradores</h1>
      <form onSubmit={handleSubmit}>
        {/* Adicione os campos do formulário aqui seguindo o exemplo abaixo */}
        <div className="form__group">
          <label className="form__label">Nome</label>
          <input type="text" name="nome" value={formData.nome} onChange={handleChange} className="form__input" />
        </div>
        {/* Adicione os demais campos do formulário aqui */}
        <button type="submit" className="button">Cadastrar</button>
      </form>
      <h2>Lista de Colaboradores</h2>
      <ul className="list">
        {collaborators.map((collaborator) => (
          <li key={collaborator._id} className="list__item">
            {collaborator.nome}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CollaboratorsPage;
