import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTasksAction } from '../store/actions/taskActions';
import '../assets/styles/dashboard.css';
import { translate } from '../components/Dictionary';

const TaskBoard = () => {
  const dispatch = useDispatch();
  const tasks = useSelector((state) => state.tasks.tasks);
  const [filteredTasks, setFilteredTasks] = useState([]);

  useEffect(() => {
    dispatch(fetchTasksAction());
  }, [dispatch]);

  useEffect(() => {
    setFilteredTasks(tasks);
  }, [tasks]);

  const handleFilterChange = (e) => {
    const status = e.target.value;
    if (status === 'all') {
      setFilteredTasks(tasks);
    } else {
      setFilteredTasks(tasks.filter(task => task.status === status));
    }
  };

  return (
    <div className="task-board">
      <h1>{translate('taskBoard')}</h1>
      <div className="filter-container">
        <label>{translate('filterByStatus')}:</label>
        <select onChange={handleFilterChange}>
          <option value="all">{translate('all')}</option>
          <option value="pending">{translate('pending')}</option>
          <option value="completed">{translate('completed')}</option>
          <option value="canceled">{translate('canceled')}</option>
        </select>
      </div>
      <ul className="task-list">
        {filteredTasks.map(task => (
          <li key={task._id} className={`task-item ${task.status}`}>
            <h3>{translate(task.type)}</h3>
            <p>{task.description}</p>
            <p>{new Date(task.date).toLocaleString()}</p>
            <p>{translate('status')}: {translate(task.status)}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TaskBoard;