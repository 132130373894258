import axios from 'axios';

// Configuração base do axios
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:3001/api',
});

// Consolidando interceptors de requisição
api.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    // Remover a tentativa de definir o header de origem
    // config.headers['Origin'] = window.location.origin;

    // Log dos headers da requisição
    console.log('Requisição Axios:', config);
    console.log('Headers da Requisição:', config.headers);
    
    // Outros ajustes na configuração da requisição podem ser feitos aqui
    return config;
  },
  error => {
    console.error('Erro na Requisição Axios:', error);
    return Promise.reject(error);
  }
);

export default api;