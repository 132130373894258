import axios from '../../utils/axiosConfig';
import { LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT } from './types';

export const loginUser = (credentials) => async (dispatch) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, credentials);
    const { token, tenantInfo, tenantId, username } = response.data;

    // Armazene o token e as informações do tenant
    localStorage.setItem('token', token);
    localStorage.setItem('tenantId', tenantId);
    localStorage.setItem('username', username);
    localStorage.setItem('tenantInfo', JSON.stringify(tenantInfo));

    dispatch({
      type: LOGIN_SUCCESS,
      payload: { token },
    });
  } catch (err) {
    dispatch({
      type: LOGIN_FAILURE,
    });
    throw err;
  }
};

export const logoutUser = () => (dispatch) => {
  localStorage.removeItem('token');
  localStorage.removeItem('tenantId');
  localStorage.removeItem('username');
  localStorage.removeItem('tenantInfo');
  dispatch({ type: LOGOUT });
};