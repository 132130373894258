// src/utils/utils.js

// Função para formatar valores monetários
export const formatCurrency = (value) => {
    if (value === undefined || value === null || isNaN(value)) {
      return "-";
    }
    return value.toFixed(2).replace(".", ",");
  };
  
  // Função para manipular mudanças de campos no formulário
  export const handleInputChange = (event, setFormData) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  
  // Função para filtrar cidades (ou outros itens) com base no texto de entrada
  export const filterCities = (inputValue, cities, setFilteredCities) => {
    if (inputValue.length >= 3) {
      setFilteredCities(
        cities
          .filter((city) =>
            city.name.toLowerCase().startsWith(inputValue.toLowerCase())
          )
          .slice(0, 10)
      );
    }
  };
  