import {
  FETCH_TRIPS_SUCCESS,
  FETCH_TRIPS_FAILURE,
  CREATE_TRIP_SUCCESS,
  CREATE_TRIP_FAILURE,
} from './types';
import { fetchTrips as fetchTripsAPI, createManifest } from '../../services/api';

export const fetchTrips = () => async (dispatch) => {
  try {
    const data = await fetchTripsAPI();
    dispatch({
      type: FETCH_TRIPS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_TRIPS_FAILURE,
      payload: error.response.data,
    });
  }
};

export const createTrip = (tripData) => async (dispatch) => {
  try {
    const data = await createManifest(tripData);
    dispatch({
      type: CREATE_TRIP_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_TRIP_FAILURE,
      payload: error.response.data,
    });
  }
};
