import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { createTaskAction } from '../store/actions/taskActions';
import '../assets/styles/clientPage.css';
import { translate } from '../components/Dictionary';

const TaskPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [taskData, setTaskData] = useState({
    type: '',
    description: '',
    date: '',
    status: 'pending',
    relatedTo: '',
    relatedModel: '',
    createdBy: '',
  });

  const [errors, setErrors] = useState({});

  const handleChange = (field, value) => {
    setTaskData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!taskData.type) newErrors.type = 'Tipo é obrigatório';
    if (!taskData.description) newErrors.description = 'Descrição é obrigatória';
    if (!taskData.date) newErrors.date = 'Data é obrigatória';
    if (!taskData.relatedTo) newErrors.relatedTo = 'Relacionado a é obrigatório';
    if (!taskData.relatedModel) newErrors.relatedModel = 'Modelo relacionado é obrigatório';
    if (!taskData.createdBy) newErrors.createdBy = 'Criado por é obrigatório';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    dispatch(createTaskAction(taskData));
    console.log(taskData);
    navigate('/tasks');
  };

  // Supondo que você tenha uma lista de usuários dos próprios tenants
  const userOptions = [
    { value: 'user1', label: 'User One' },
    { value: 'user2', label: 'User Two' },
  ];

  return (
    <div className="container">
      <h1 className="page-title">{translate('submit_task')}</h1>
      <form onSubmit={handleSubmit}>
        <h2>{translate('taskDetails')}</h2>
        <select
          className="form-select"
          value={taskData.type}
          onChange={(e) => handleChange('type', e.target.value)}
        >
          <option value="">{translate('selectType')}</option>
          <option value="meeting">{translate('meeting')}</option>
          <option value="call">{translate('call')}</option>
          <option value="email">{translate('email')}</option>
          <option value="other">{translate('other')}</option>
        </select>
        {errors.type && <span className="error">{errors.type}</span>}
        <input
          className="form-input"
          type="text"
          placeholder={translate('description')}
          value={taskData.description}
          onChange={(e) => handleChange('description', e.target.value)}
        />
        {errors.description && <span className="error">{errors.description}</span>}
        <input
          className="form-input"
          type="datetime-local"
          placeholder={translate('date')}
          value={taskData.date}
          onChange={(e) => handleChange('date', e.target.value)}
        />
        {errors.date && <span className="error">{errors.date}</span>}
        <select
          className="form-select"
          value={taskData.status}
          onChange={(e) => handleChange('status', e.target.value)}
        >
          <option value="pending">{translate('pending')}</option>
          <option value="completed">{translate('completed')}</option>
          <option value="canceled">{translate('canceled')}</option>
        </select>
        <input
          className="form-input"
          type="text"
          placeholder={translate('relatedTo')}
          value={taskData.relatedTo}
          onChange={(e) => handleChange('relatedTo', e.target.value)}
        />
        {errors.relatedTo && <span className="error">{errors.relatedTo}</span>}
        <select
          className="form-select"
          value={taskData.relatedModel}
          onChange={(e) => handleChange('relatedModel', e.target.value)}
        >
          <option value="">{translate('selectRelatedModel')}</option>
          <option value="Lead">{translate('lead')}</option>
          <option value="Client">{translate('client')}</option>
        </select>
        {errors.relatedModel && <span className="error">{errors.relatedModel}</span>}
        <Select
          className="form-select"
          value={userOptions.find(option => option.value === taskData.createdBy)}
          onChange={(selectedOption) => handleChange('createdBy', selectedOption ? selectedOption.value : '')}
          options={userOptions}
          placeholder={translate('selectUser')}
        />
        {errors.createdBy && <span className="error">{errors.createdBy}</span>}
        <button className="form-button" type="submit">{translate('submit')}</button>
      </form>
    </div>
  );
};

export default TaskPage;