import React from 'react';

const HouseBillPage = () => {
  // Implementar lógica para exibir House Bills e coletar mais dados
  return (
    <div className="container housebill-page__container">
      <h1 className="housebill-page__title">House Bills</h1>
      {/* Adicionar lista de House Bills */}
    </div>
  );
};

export default HouseBillPage;
