// src/store/actions/masterBillActions.js
import axios from '../../utils/axiosConfig';

import {
  FETCH_MASTER_BILLS_SUCCESS,
  FETCH_MASTER_BILLS_FAILURE,
  CREATE_MASTER_BILL_SUCCESS,
  CREATE_MASTER_BILL_FAILURE,
  UPDATE_MASTER_BILL_SUCCESS,
  UPDATE_MASTER_BILL_FAILURE,
} from './types';

import { fetchMasterBills as fetchMasterBillsAPI, createMasterBill as createMasterBillAPI, updateMasterBill as updateMasterBillAPI } from '../../services/api';

// Ações de busca de Master Bills
export const fetchMasterBills = () => async dispatch => {
  dispatch({ type: 'FETCH_MASTER_BILLS_REQUEST' });

  try {
    const response = await axios.get('/api/masterbills');
    dispatch({ type: 'FETCH_MASTER_BILLS_SUCCESS', payload: response.data });
  } catch (error) {
    dispatch({ type: 'FETCH_MASTER_BILLS_FAILURE', error: error.message });
  }
};

// Ação de criação de Master Bill
export const createMasterBill = (masterBillData) => async (dispatch) => {
  try {
    const res = await createMasterBillAPI(masterBillData);
    dispatch({
      type: CREATE_MASTER_BILL_SUCCESS,
      payload: res,
    });
  } catch (err) {
    dispatch({
      type: CREATE_MASTER_BILL_FAILURE,
      payload: err.response.data.message,
    });
  }
};

// Ação de atualização de Master Bill
export const updateMasterBill = (id, masterBillData) => async (dispatch) => {
  try {
    const res = await updateMasterBillAPI(id, masterBillData);
    dispatch({
      type: UPDATE_MASTER_BILL_SUCCESS,
      payload: res,
    });
  } catch (err) {
    dispatch({
      type: UPDATE_MASTER_BILL_FAILURE,
      payload: err.response.data.message,
    });
  }
};
