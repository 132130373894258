import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { createClientAction } from '../store/actions/clientActions';
import '../assets/styles/clientPage.css';
import { translate } from '../components/Dictionary';
import cities from '../json/cities.json';

const ClientPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [clientData, setClientData] = useState({
    registrationSection: {
      type: '',
      name: '',
      CPF_CNPJ: '',
      RG_IE: '',
      email: '',
      phone: '',
      address: {
        street: '',
        number: '',
        complement: '',
        district: '',
        city: '',
        state: '',
        zip: '',
        city_code: '',
      },
    },
    contactSection: [
      {
        name: '',
        position: '',
        email: '',
        phone: '',
        main: false,
        notes: '', // Adicionando o campo de notas
      },
    ],
    financeSection: {
      creditLimit: '',
      paymentTerms: '',
      creditRisk: '',
      notes: '',
    },
  });

  const [errors, setErrors] = useState({});

  const getCityCode = (cityName) => {
    const city = cities.find(city => city.name === cityName);
    return city ? city.City_Code : '';
  };

  const handleChange = (section, field, value) => {
    setClientData((prevData) => ({
      ...prevData,
      [section]: {
        ...prevData[section],
        [field]: value,
      },
    }));
  };

  const handleAddressChange = (field, value) => {
    setClientData((prevData) => ({
      ...prevData,
      registrationSection: {
        ...prevData.registrationSection,
        address: {
          ...prevData.registrationSection.address,
          [field]: value,
        },
      },
    }));
  };

  const handleCityChange = (selectedOption) => {
    const cityName = selectedOption ? selectedOption.value : '';
    const city_code = getCityCode(cityName);
    setClientData((prevData) => ({
      ...prevData,
      registrationSection: {
        ...prevData.registrationSection,
        address: {
          ...prevData.registrationSection.address,
          city: cityName,
          city_code: city_code,
        },
      },
    }));
  };

  const handleContactChange = (index, field, value) => {
    const newContacts = [...clientData.contactSection];
    newContacts[index][field] = value;
    setClientData((prevData) => ({
      ...prevData,
      contactSection: newContacts,
    }));
  };

  const addContact = () => {
    setClientData((prevData) => ({
      ...prevData,
      contactSection: [
        ...prevData.contactSection,
        { name: '', position: '', email: '', phone: '', main: false, notes: '' }, // Adicionando o campo de notas
      ],
    }));
  };

  const removeContact = (index) => {
    setClientData((prevData) => ({
      ...prevData,
      contactSection: prevData.contactSection.filter((_, i) => i !== index),
    })); 
  };

  const validateForm = () => {
    const newErrors = {};
    clientData.contactSection.forEach((contact, index) => {
      if (!contact.name) newErrors[`contactSection.${index}.name`] = 'Nome é obrigatório';
      if (!contact.email) newErrors[`contactSection.${index}.email`] = 'Email é obrigatório';
      if (!contact.phone) newErrors[`contactSection.${index}.phone`] = 'Telefone é obrigatório';
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    const formattedClientData = {
      ...clientData,
      financeSection: {
        ...clientData.financeSection,
        creditLimit: Number(clientData.financeSection.creditLimit),
        paymentTerms: Number(clientData.financeSection.paymentTerms),
        creditRisk: Number(clientData.financeSection.creditRisk),
      },
    };
    dispatch(createClientAction(formattedClientData));
    console.log(formattedClientData);
    navigate('/clients');
  };

  const cityOptions = cities.map(city => ({
    value: city.name,
    label: city.name,
  }));

  const filterOption = (option, inputValue) => {
    const normalizedLabel = option.label.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
    const normalizedInput = inputValue.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
    return normalizedInput.length >= 3 && normalizedLabel.includes(normalizedInput);
  };

  return (
    <div className="container">
      <h1 className="page-title">{translate('submit_client')}</h1>
      <form onSubmit={handleSubmit}>
        <h2>{translate('registrationSection')}</h2>
        <select
          className="form-select"
          value={clientData.registrationSection.type}
          onChange={(e) => handleChange('registrationSection', 'type', e.target.value)}
        >
          <option value="">{translate('Selecione PF/PJ')}</option>
          <option value="Fisica">PF (Pessoa Física)</option>
          <option value="Juridica">PJ (Pessoa Jurídica)</option>
        </select>
        <input
          className="form-input"
          type="text"
          placeholder={translate('name')}
          value={clientData.registrationSection.name}
          onChange={(e) => handleChange('registrationSection', 'name', e.target.value)}
        />
        <input
          className="form-input"
          type="text"
          placeholder={translate('CPF_CNPJ')}
          value={clientData.registrationSection.CPF_CNPJ}
          onChange={(e) => handleChange('registrationSection', 'CPF_CNPJ', e.target.value)}
        />
        <input
          className="form-input"
          type="text"
          placeholder={translate('RG_IE')}
          value={clientData.registrationSection.RG_IE}
          onChange={(e) => handleChange('registrationSection', 'RG_IE', e.target.value)}
        />
        <input
          className="form-input"
          type="email"
          placeholder={translate('email')}
          value={clientData.registrationSection.email}
          onChange={(e) => handleChange('registrationSection', 'email', e.target.value)}
        />
        <input
          className="form-input"
          type="text"
          placeholder={translate('phone')}
          value={clientData.registrationSection.phone}
          onChange={(e) => handleChange('registrationSection', 'phone', e.target.value)}
        />
        <h3>{translate('address')}</h3>
        <input
          className="form-input"
          type="text"
          placeholder={translate('street')}
          value={clientData.registrationSection.address.street}
          onChange={(e) => handleAddressChange('street', e.target.value)}
        />
        <input
          className="form-input"
          type="text"
          placeholder={translate('number')}
          value={clientData.registrationSection.address.number}
          onChange={(e) => handleAddressChange('number', e.target.value)}
        />
        <input
          className="form-input"
          type="text"
          placeholder={translate('complement')}
          value={clientData.registrationSection.address.complement}
          onChange={(e) => handleAddressChange('complement', e.target.value)}
        />
        <input
          className="form-input"
          type="text"
          placeholder={translate('district')}
          value={clientData.registrationSection.address.district}
          onChange={(e) => handleAddressChange('district', e.target.value)}
        />
        <Select
          className="form-select"
          value={cityOptions.find(option => option.value === clientData.registrationSection.address.city)}
          onChange={handleCityChange}
          options={cityOptions}
          placeholder={translate('selectCity')}
          filterOption={filterOption}
        />
        <input
          className="form-input"
          type="text"
          placeholder={translate('state')}
          value={clientData.registrationSection.address.state}
          onChange={(e) => handleAddressChange('state', e.target.value)}
        />
        <input
          className="form-input"
          type="text"
          placeholder={translate('zip')}
          value={clientData.registrationSection.address.zip}
          onChange={(e) => handleAddressChange('zip', e.target.value)}
        />

        <h2>{translate('contactSection')}</h2>
        {clientData.contactSection.map((contact, index) => (
          <React.Fragment key={index}>
            <input
              className="form-input"
              type="text"
              placeholder={translate('name')}
              value={contact.name}
              onChange={(e) => handleContactChange(index, 'name', e.target.value)}
            />
            {errors[`contactSection.${index}.name`] && <span className="error">{errors[`contactSection.${index}.name`]}</span>}
            <input
              className="form-input"
              type="text"
              placeholder={translate('position')}
              value={contact.position}
              onChange={(e) => handleContactChange(index, 'position', e.target.value)}
            />
            <input
              className="form-input"
              type="email"
              placeholder={translate('email')}
              value={contact.email}
              onChange={(e) => handleContactChange(index, 'email', e.target.value)}
            />
            {errors[`contactSection.${index}.email`] && <span className="error">{errors[`contactSection.${index}.email`]}</span>}
            <input
              className="form-input"
              type="text"
              placeholder={translate('phone')}
              value={contact.phone}
              onChange={(e) => handleContactChange(index, 'phone', e.target.value)}
            />
            {errors[`contactSection.${index}.phone`] && <span className="error">{errors[`contactSection.${index}.phone`]}</span>}
            <textarea
              className="form-textarea"
              placeholder={translate('notes')}
              value={contact.notes}
              onChange={(e) => handleContactChange(index, 'notes', e.target.value)}
            />
            <label>
              {translate('main')}:
              <input
                type="checkbox"
                checked={contact.main}
                onChange={(e) => handleContactChange(index, 'main', e.target.checked)}
              />
            </label>
            
            <button type="button" onClick={() => removeContact(index)}>
              {translate('removeContact')}
            </button>
          </React.Fragment>
        ))}
        <button type="button" onClick={addContact}>
          {translate('addContact')}
        </button>

        <h2>{translate('financeSection')}</h2>
        <input
          className="form-input"
          type="number"
          placeholder={translate('creditLimit')}
          value={clientData.financeSection.creditLimit}
          onChange={(e) => handleChange('financeSection', 'creditLimit', e.target.value)}
        />
        <input
          className="form-input"
          type="number"
          placeholder={translate('paymentTerms')}
          value={clientData.financeSection.paymentTerms}
          onChange={(e) => handleChange('financeSection', 'paymentTerms', e.target.value)}
        />
        <input
          className="form-input"
          type="number"
          placeholder={translate('creditRisk')}
          value={clientData.financeSection.creditRisk}
          onChange={(e) => handleChange('financeSection', 'creditRisk', e.target.value)}
        />
        <textarea
          className="form-textarea"
          placeholder={translate('notes')}
          value={clientData.financeSection.notes}
          onChange={(e) => handleChange('financeSection', 'notes', e.target.value)}
        />

        <button className="form-button" type="submit">{translate('submit')}</button>
      </form>
    </div>
  );
};

export default ClientPage;