// src/pages/MasterBillPage.js

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMasterBills } from '../store/actions/masterBillActions';

const MasterBillPage = () => {
  const dispatch = useDispatch();
  const { masterBillList, loading, error } = useSelector(state => state.masterBills);

  useEffect(() => {
    dispatch(fetchMasterBills());
  }, [dispatch]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div>
      <h1>Master Bills</h1>
      <ul>
        {masterBillList.map(bill => (
          <li key={bill.id}>{bill.name}</li>
        ))}
      </ul>
    </div>
  );
};

export default MasterBillPage;
